define('ticket-sales-frontend/routes/ticket-dashboard', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    session: _ember['default'].inject.service('session'),
    getTerminalData: function getTerminalData(obj) {
      var _this = this;

      return _emberData['default'].PromiseObject.create({
        promise: new Promise(function (resolve) {
          _this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/status').then(function (boothStatus) {
            localforage.getItem('terminalData').then(function (data) {
              if (data && data.id) {
                _this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/terminal/' + data.id).then(function (terminalData) {
                  obj.terminal = terminalData;
                  // obj.quotes = quotes;
                  if (boothStatus.terminalId === terminalData.terminalId) {
                    obj.boothStatus = boothStatus;
                  } else {
                    // Terminal being used by another user
                  }
                  resolve(obj);
                  /*
                  jQuery.ajax({
                    url: ENV.apiURL + '/search/localTrip',
                    data: JSON.stringify({
                      origin: terminalData.stopOffName,
                      adultCount: 1
                    }),
                    contentType: 'application/json; charset=utf-8',
                    method: 'POST'
                  }).then((quotes) => {
                  });
                  */
                });
              } else {
                  resolve({});
                }
            });
          }, function (error) {
            console.log(error);
            if (gapi && gapi.auth2) {
              gapi.auth2.getAuthInstance().signOut();
            }
            _this.set('session.data.profile', null);
            _this.get('session').invalidate();
            _this.container.lookup('controller:application').transitionToRoute('index');
          });
        })
      });
    },
    model: function model() {
      return this.getTerminalData({});
    }
  });
});