define('ticket-sales-frontend/components/ticket-client-search', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    search: _ember['default'].Object.create({
      origin: {},
      destination: {},
      adultCount: 1,
      departureDate: new Date()
    }),
    currentDate: moment().subtract(1, 'days').toDate(),
    defaultOrigin: '',
    init: function init() {
      this._super();
      this.lookupDestinations = this.lookupDestinations.bind(this);
      if (this.get('defaultOriginal')) {
        this.set('search.origin', {
          value: this.get('defaultOrigin')
        });
      }
    },
    reset: function reset() {
      this.set('search.destination', {});
      this.set('search.departureDate', new Date());
      this.set('search.origin', {
        value: this.get('defaultOrigin')
      });
    },
    didInsertElement: function didInsertElement() {
      this.set('searchButton', Ladda.create(this.$().find('button').get(0)));
      this.set('register-as', this);
    },
    lookupBeginnings: function lookupBeginnings(text) {
      var beginningSuggestionsUrl = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/beginning';
      return jQuery.get(beginningSuggestionsUrl, { s: text });
    },
    lookupDestinations: function lookupDestinations(text) {
      var destinationSuggestionsUrl = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/destination';
      return jQuery.get(destinationSuggestionsUrl, {
        o: this.get('search').origin.value,
        s: text
      });
    },
    actions: {
      selectOrigin: function selectOrigin(origin) {
        this.set('search.origin', origin);
        console.log(document.getElementById('destinationClientSearch'));
      },
      setInitialBeginningOpts: function setInitialBeginningOpts() {
        if (!this.get('options')) {
          var destinationSuggestionsUrl = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/beginning';
          var data = _emberData['default'].PromiseArray.create({
            promise: jQuery.get(destinationSuggestionsUrl, {
              s: ''
            })
          });
          this.set('beginningOptions', data);
        }
      },
      setDestinationOpts: function setDestinationOpts() {
        var destinationSuggestionsUrl = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/destination';
        var data = _emberData['default'].PromiseArray.create({
          promise: jQuery.get(destinationSuggestionsUrl, {
            o: this.get('search').origin.value,
            s: ''
          })
        });
        this.set('destinationOptions', data);
      },
      searchBeginningDestinations: function searchBeginningDestinations(text) {
        var destinationSuggestionsUrl = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/beginning';
        return _emberData['default'].PromiseArray.create({
          promise: jQuery.get(destinationSuggestionsUrl, {
            s: text
          })
        });
      },
      searchEndingDestinations: function searchEndingDestinations(text) {
        var destinationSuggestionsUrl = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/destination';
        return _emberData['default'].PromiseArray.create({
          promise: jQuery.get(destinationSuggestionsUrl, {
            o: this.get('search').origin.value,
            s: text
          })
        });
      },
      searchTrip: function searchTrip() {
        this.get('searchButton').start();
        var search = _ember['default'].merge({}, this.get('search'));

        search.origin = search.origin.value;
        search.destination = search.destination.value;
        search.departureDate = search.departureDate && search.departureDate.toISOString();
        search.returningDate = search.returningDate && search.returningDate.toISOString();
        search.searchButton = this.get('searchButton');

        this.sendAction('searchAction', search);
      }
    }
  });
});