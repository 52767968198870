define('ticket-sales-frontend/routes/booth-list', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    session: _ember['default'].inject.service('session'),
    model: function model() {
      var _this = this;

      return _emberData['default'].PromiseObject.create({
        promise: new Promise(function (resolve) {
          localforage.getItem('terminalData').then(function (data) {
            if (data && data.id) {
              _this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/sales?terminalId=' + data.id).then(function (booths) {
                _this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/employees/salesmen').then(function (salesmen) {
                  resolve({
                    booths: booths,
                    salesmen: salesmen.map(function (s) {
                      s.fullName = s.name + ' ' + s.lastName + ' ' + s.secondLastName;
                      return s;
                    })
                  });
                });
              });
            } else {
              resolve({});
            }
          });
        })
      });
    },
    actions: {
      startShift: function startShift(booth) {
        _ember['default'].set(booth, 'startShift', true);
      },
      saveShift: function saveShift(booth) {
        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/startShift?terminalId=' + booth.id + '&employeeId=' + booth.salesmen + '&amount=' + booth.amount).then(function (start) {
          _ember['default'].set(booth, 'startShift', false);
          for (var x in start) {
            _ember['default'].set(booth, x, start[x]);
          }
          location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/downloadStartShift/' + booth.id + '.pdf?timeZone=' + 'America/Mexico_City';
        });
      },
      recordSnapshot: function recordSnapshot(booth) {
        _ember['default'].set(booth, 'startSnapshot', true);
      },
      saveRecordSnapshot: function saveRecordSnapshot(booth) {
        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/recordSnapshot?terminalId=' + booth.id + '&amount=' + booth.amount).then(function (start) {
          _ember['default'].set(booth, 'startSnapshot', false);
          for (var x in start) {
            _ember['default'].set(booth, x, start[x]);
          }
          location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/downloadRecordSnapshot/' + booth.id + '.pdf?timeZone=' + 'America/Mexico_City';
        });
      },
      closeShift: function closeShift(booth) {
        _ember['default'].set(booth, 'closeShift', true);
      },
      saveCloseShift: function saveCloseShift(booth) {
        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/closeShift?terminalId=' + booth.id).then(function (close) {
          _ember['default'].set(booth, 'closeShift', false);
          for (var x in close) {
            _ember['default'].set(booth, x, close[x]);
          }
          location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/downloadCloseShift/' + booth.latestShiftId + '.pdf?timeZone=' + 'America/Mexico_City';
        });
      }
    }
  });
});