define('ticket-sales-frontend/routes/admin-payments', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    session: _ember['default'].inject.service('session'),
    model: function model() {
      var _this = this;

      return _emberData['default'].PromiseObject.create({
        promise: new Promise(function (resolve) {
          localforage.getItem('terminalData').then(function (data) {
            if (!data) {
              jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/destinations').then(function (destinations) {
                resolve({
                  status: null,
                  destinations: destinations
                });
                return;
              });
            }
            _this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/payment/status?terminalId=' + data.id).then(function (boothStatus) {
              jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/destinations').then(function (destinations) {
                resolve({
                  status: boothStatus,
                  destinations: destinations
                });
              });
            }, function (error) {
              console.log(error);
              if (gapi && gapi.auth2) {
                gapi.auth2.getAuthInstance().signOut();
              }
              _this.set('session.data.profile', null);
              _this.get('session').invalidate();
              _this.container.lookup('controller:application').transitionToRoute('index');
            });
          });
        })
      });
    }
  });
});