define('ticket-sales-frontend/routes/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    moment: _ember['default'].inject.service(),
    beforeModel: function beforeModel() {
      var profile = this.get('session.data.profile');
      this.get('moment').changeLocale('es');
      this.get('moment').changeTimeZone('America/Mexico_City');
      // moment.tz.setDefault('America/Mexico_City');
      // if(profile && profile.roles && profile.roles.indexOf('oficina') >= 0) {
      //   this.transitionTo('admin-dashboard');
      // }
    },
    actions: {
      logout: function logout() {
        if (gapi && gapi.auth2) {
          gapi.auth2.getAuthInstance().signOut();
        }
        this.set('session.data.profile', null);
        this.get('session').invalidate();
        this.container.lookup('controller:application').transitionToRoute('index');
      }
    }
  });
});