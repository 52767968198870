define('ticket-sales-frontend/controllers/trip-list', ['exports', 'ember', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    search: {},
    actions: {
      searchTrips: function searchTrips(search) {
        var startDate = moment(search.from).toISOString();
        var endDate = moment(search.to).toISOString();

        var trips = DS.PromiseArray.create({
          promise: jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/trips/listTrips?startingDate=' + startDate + '&endingDate=' + endDate)
        });
        this.set('trips', trips);
      },
      downloadTripGuide: function downloadTripGuide(trip) {
        location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/downloadBoardingList/' + trip.id + '.pdf?timeZone=' + 'America/Mexico_City';
      }
    }
  });
});