define('ticket-sales-frontend/components/ticket-booth-payment', ['exports', 'ember', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    paymentType: 'cash',
    address: {},
    billingAddress: {},
    billingData: {},
    general: {
      needBill: false,
      payedAmount: 0
    },
    paymentTypes: [{ id: 'TRANSFER', name: 'Transferencia Bancaria' }, { id: 'CREDIT_CARD', name: 'Tarjeta de crédito' }, { id: 'CASH', name: 'Efectivo' }, { id: 'ACCOUNT', name: 'Monedero electrónico' }],
    parts: [],
    temp: {
      paymentType: 'CASH'
    },
    totalAmount: _ember['default'].computed('parts.[]', function () {
      var payedAmount = this.get('parts').reduce(function (total, part) {
        return total + parseFloat(part.amount);
      }, 0);
      return payedAmount;
    }),
    validAmount: _ember['default'].computed('parts.[]', function () {
      var payedAmount = this.get('parts').reduce(function (total, part) {
        return total + parseFloat(part.amount);
      }, 0);
      console.log(this.get('sale'), payedAmount);
      return !this.get('sale.totalPrice') || payedAmount >= parseFloat(this.get('sale.totalPrice'));
    }),
    changeAmount: _ember['default'].computed('totalAmount', function () {
      return this.get('totalAmount') - parseFloat(this.get('sale.totalPrice'));
    }),
    showChange: _ember['default'].computed('changeAmount', function () {
      return this.get('changeAmount') > 0;
    }),
    buildBaseData: function buildBaseData(general, billingAddress, billingData, sale, passengers, parts) {
      var changeAmount = null;
      if (general.paymentType === 'cash') {
        changeAmount = this.get('changeAmount');
      }
      return {
        email: general.email,
        passengers: passengers,
        departureTrip: {
          id: sale && sale.departureTrip && sale.departureTrip.get('id')
        },
        totalPrice: sale && sale.totalPrice,
        paymentParts: parts,
        payedAmount: general.payedAmount,
        changeAmount: changeAmount,
        paymentMethod: general.paymentType,
        needBill: general.needBill,
        billingAddress: billingAddress,
        billingData: billingData,
        returnTrip: {
          id: sale && sale.returnTrip && sale.returnTrip.get('id')
        },
        departureSegment: {
          startingStop: {
            id: sale && sale.departureQuote && sale.departureQuote.origin.id
          },
          endingStop: {
            id: sale && sale.departureQuote && sale.departureQuote.destination.id
          }
        },
        returnSegment: {
          startingStop: {
            id: sale && sale.returnQuote && sale.returnQuote && sale.returnQuote.origin.id
          },
          endingStop: {
            id: sale && sale.returnQuote && sale.returnQuote.destination.id
          }
        }
      };
    },
    actions: {
      addPaymentType: function addPaymentType(part) {
        var _this = this;

        this.set('paymentError', '');
        part = {
          amount: part.amount,
          reference: part.reference,
          paymentType: part.paymentType,
          cardNumber: part.cardNumber
        };
        if (!part.amount) {
          this.set('paymentError', 'El monto especificado es invalido');
          return;
        }
        if (part && part.paymentType) {
          if (part.paymentType === 'ACCOUNT') {
            if (!part.cardNumber) {
              this.set('paymentError', 'El número de monedero es inválido');
              return;
            }
            jQuery.ajax({
              url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/couponData/' + part.cardNumber,
              success: function success(data) {
                if (!data) {
                  _this.set('paymentError', 'El número de monedero es inválido');
                  return;
                }
                if (data.name != part.reference) {
                  _this.set('paymentError', 'La referencia no corresponde con la referencia registrada en el sistema');
                  return;
                }
                if (part.amount <= data.amount) {
                  _this.get('parts').addObject({
                    paymentType: 'ACCOUNT',
                    amount: part.amount,
                    leftover: data.amount - part.amount,
                    reference: data.name
                  });
                  _this.set('temp.amount', null);
                  _this.set('temp.paymentType', 'CASH');
                  _this.set('temp.cardNumber', '');
                  _this.set('temp.reference', '');
                } else {
                  _this.set('paymentError', 'El total del monedero es de ' + data.amount + ' y no es suficiente para completar este pago');
                }
              },
              error: function error(data) {
                _this.set('paymentError', 'El número o la referencia del monedero son invalidos');
              }
            });
          } else if (part.amount) {
            this.get('parts').addObject(part);
            this.set('temp.amount', null);
            this.set('temp.paymentType', 'CASH');
            this.set('temp.reference', '');
          }
        }
      },
      deletePart: function deletePart(part) {
        this.get('parts').removeObject(part);
      },
      returnToBus: function returnToBus() {
        this.sendAction('returnReceived');
      },
      submitPayment: function submitPayment() {
        var general = this.get('general');
        var billingAddress = this.get('billingAddress');
        var billingData = this.get('billingData');
        var saleData = this.get('sale');
        var passengers = this.get('passengers');
        var parts = this.get('parts');
        var payedAmount = parts.reduce(function (total, part) {
          return total + parseFloat(part.amount);
        }, 0);
        general.payedAmount = payedAmount;
        general.paymentType = this.get('paymentType');
        var data = this.buildBaseData(general, billingAddress, billingData, saleData, passengers, parts);
        this.sendAction('paymentReceived', data);
      }
    },
    reset: function reset() {
      this.get('parts').clear();
    },
    didInsertElement: function didInsertElement() {
      this.set('register-as', this);
    }
  });
});