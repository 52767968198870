define('ticket-sales-frontend/controllers/booth-detail', ['exports', 'ember', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    session: _ember['default'].inject.service('session'),
    report: {
      from: null,
      to: null
    },
    actions: {
      download: function download(person) {
        location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/downloadDetail/' + person.id + '.pdf?timeZone=' + 'America/Mexico_City' + '&startDate=' + this.get('report.from').toISOString() + '&endDate=' + this.get('report.to').toISOString();
      },
      searchSalesPeople: function searchSalesPeople(report) {
        var _this = this;

        localforage.getItem('terminalData').then(function (data) {
          _this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/office/people/' + data.id + '?startDate=' + report.from.toISOString() + '&endDate=' + report.to.toISOString()).then(function (salesmen) {
            _this.set('salesmen', salesmen);
          });
        });
      }
    }
  });
});