define('ticket-sales-frontend/initializers/fb', ['exports', 'ember-cli-facebook-js-sdk/fb'], function (exports, _emberCliFacebookJsSdkFb) {
  exports.initialize = initialize;

  function initialize() {
    return _emberCliFacebookJsSdkFb['default'].init({
      appId: '181496698867701',
      version: 'v2.5',
      xfbml: true
    });
  }

  exports['default'] = {
    name: 'fb',
    initialize: initialize
  };
});