define('ticket-sales-frontend/routes/location-map', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    model: function model() {
      if ($.inArray('view-gps', this.get('session.data.profile.permissions')) >= 0) {
        return _emberData['default'].PromiseArray.create({
          promise: this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/tracking/locations')
        });
      } else {
        location.href = '/';
      }
    }
  });
});