define('ticket-sales-frontend/services/authorized-request', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service('session'),
    ajax: function ajax(url, settings) {
      if (!settings) {
        settings = {};
      }
      settings.headers = {
        Authorization: 'Bearer ' + this.get('session.data.authenticated.access_token')
      };
      return jQuery.ajax(url, settings);
    }
  });
});