define('ticket-sales-frontend/controllers/package-dashboard-search', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    session: _ember['default'].inject.service('session'),
    tz: 'America/Mexico_City',
    actions: {
      showDelete: function showDelete(ticket) {
        _ember['default'].set(ticket, 'predelete', true);
      },
      hideDelete: function hideDelete(ticket) {
        _ember['default'].set(ticket, 'predelete', false);
      },
      deleteTicket: function deleteTicket(ticket, cardNumber, phoneNumber, returnMoney) {
        var _this = this;

        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/cancelPackageTicket/' + ticket.id + '?cardNumber=' + cardNumber + '&returnMoney=' + returnMoney + '&phoneNumber=' + phoneNumber).then(function (data) {
          _this.set('finishedSale', null);
          _this.set('sales', null);
          _this.set('couponData', data);
        });
      },
      searchByName: function searchByName(ticketName) {
        var _this2 = this;

        jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/findByPackageName?name=' + ticketName
        }).then(function (sales) {
          _this2.set('sales', sales);
        });
      },
      search: function search(ticketNumber) {
        var _this3 = this;

        jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/packageSaleData/' + ticketNumber
        }).then(function (saleData) {
          _this3.set('finishedSale', saleData);
        });
      }
    }
  });
});