define('ticket-sales-frontend/controllers/admin-loans', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    section: null,
    selected: {
      operator: null,
      type: null,
      concept: null
    },
    concepts: [{
      id: 'unit-damage',
      text: 'Daño general a la unidad'
    }, {
      id: 'personal',
      text: 'Personal'
    }, {
      id: 'forgotten-passenger',
      text: 'Pasajero olvidado'
    }, {
      id: 'lost-suitcase',
      text: 'Equipaje perdido'
    }, {
      id: 'advance',
      text: 'Anticipo de sueldo'
    }, {
      id: 'diesel-exceded',
      text: 'Excedente de diesel'
    }, {
      id: 'other',
      text: 'Otro'
    }],
    types: [{
      id: 'percentage',
      text: 'Porcentaje'
    }, {
      id: 'number',
      text: 'Monto'
    }],
    account: _ember['default'].computed('selected.operator', function () {
      if (this.get('selected.operator')) {
        this.set('section', 'authorize');
      }
      return _emberData['default'].PromiseObject.create({
        promise: jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/loan/account/' + this.get('selected.operator'))
      });
    }),
    actions: {
      modify: function modify(loan) {
        this.set('section', 'modify');
        this.set('selectedLoan', loan);
      },
      saveNewAmount: function saveNewAmount(loan, newAmount) {
        var _this = this;

        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/loan/changeAmount', {
          method: 'POST',
          data: JSON.stringify({
            id: loan.id,
            amount: newAmount
          }),
          contentType: 'application/json; charset=utf-8'
        }).then(function () {
          _this.set('selected.operator', null);
          _this.set('section', null);
        });
      },
      authorize: function authorize(userId, amount, type, num, observations, concept) {
        var _this2 = this;

        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/loan/authorizeLoan', {
          method: 'POST',
          data: JSON.stringify({
            userId: userId,
            amount: amount,
            observations: observations,
            concept: concept,
            type: type,
            num: num
          }),
          contentType: 'application/json; charset=utf-8'
        }).then(function () {
          _this2.set('selected.operator', null);
        });
      }
    }
  });
});