define('ticket-sales-frontend/components/ticket-seats', ['exports', 'ember', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    seats: ['SEAT', 'SEAT_AND_TV', 'EXTRA_SEAT'],
    currentFloor: 0,
    edition: true,
    showName: false,
    booth: false,
    init: function init() {
      var _this = this;

      var freeCount = 0;
      var frontTakeover = 15;
      var backTakeover = 10;
      var totalTakeover = frontTakeover + backTakeover;
      this._super();
      if (!this.get('trip')) {
        return;
      }
      var partiallyReserved = [];
      var booth = this.get('booth');
      this.get('passengers').forEach(function (passenger, idx) {
        passenger.idx = idx;
      });
      var tripProcess = function tripProcess(trip) {
        trip.positions.forEach(function (floor) {
          floor.forEach(function (row) {
            row.filter(function (position) {
              return position;
            }).forEach(function (position) {
              if (!position.icon) {
                var icon = _this.translateIcon(position.type) + '-unselected';
                _ember['default'].set(position, 'showNumber', _this.showNumber(position.type));
                if (position.reserved) {
                  if (booth) {
                    if (position.status === 'OCCUPIED') {
                      icon = _this.translateIcon(position.type) + '-occupied';
                    } else {
                      icon = _this.translateIcon(position.type) + '-partially-reserved';
                    }
                    partiallyReserved.push(position.name);
                    _ember['default'].set(position, 'showData', 'show-data');
                  } else {
                    icon = _this.translateIcon(position.type) + '-reserved';
                  }
                } else if (_this.get('seats').indexOf(position.type) >= 0) {
                  freeCount++;
                }
                _ember['default'].set(position, 'widthClass', 'bus-width-' + position.width);
                _ember['default'].set(position, 'heightClass', 'bus-height-' + position.width);
                _ember['default'].set(position, 'icon', icon);
              }
            });
          });
        });
        _this.set('partiallyReserved', partiallyReserved);
        if (freeCount - totalTakeover >= 5 && !booth) {
          _this.takeover(trip, frontTakeover, backTakeover);
        }
      };
      if (this.get('trip').then) {
        this.get('trip').then(tripProcess);
      } else {
        tripProcess(this.get('trip'));
      }
      if (this.get('edition')) {
        _ember['default'].set(this.get('passengers').get(0), 'selected', true);
        this.set('currentPassenger', this.get('passengers').get(0));
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      if (this.get('partiallyReserved')) {
        this.get('partiallyReserved').forEach(function (reservation) {
          jQuery.ajax({
            url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/trip/' + _this2.get('trip.id') + '/' + reservation,
            success: function success(data) {
              var html = '<strong>Pasajes usados</strong><br/>';
              data.forEach(function (trip) {
                html += trip.startingStop + ' - ' + trip.endingStop + '<br />';
              });
              jQuery('#position-data-' + reservation).html(html);
              setTimeout(function () {
                jQuery('.show-data').darkTooltip({});
              }, 400);
            }
          });
        });
      }
    },
    takeover: function takeover(trip, frontTakeover, backTakeover) {
      var _this3 = this;

      var possibilities = [];

      trip.positions.forEach(function (floor) {
        floor.forEach(function (row) {
          row.forEach(function (position) {
            if (position && !position.reserved && _this3.get('seats').indexOf(position.type) >= 0) {
              possibilities.push(position);
            }
          });
        });
      });

      possibilities.sort(function (a, b) {
        return parseInt(a.name, 10) - parseInt(b.name, 10);
      });

      var half = possibilities.length / 2;
      if (half < frontTakeover) {
        half = frontTakeover;
      }

      var frontPossibilities = possibilities.slice(0, half);
      var backPossibilities = possibilities.slice(half);

      var frontPositions = this.fisherYates(frontPossibilities, frontTakeover);
      var backPositions = this.fisherYates(backPossibilities, backTakeover);

      frontPositions.forEach(function (pos) {
        var icon = _this3.translateIcon(pos.type) + '-reserved';
        _ember['default'].set(pos, 'icon', icon);
      });
      backPositions.forEach(function (pos) {
        var icon = _this3.translateIcon(pos.type) + '-reserved';
        _ember['default'].set(pos, 'icon', icon);
      });
    },
    fisherYates: function fisherYates(myArray, nb_picks) {
      for (var i = myArray.length - 1; i > 1; i--) {
        var r = Math.floor(Math.random() * i);
        var t = myArray[i];
        myArray[i] = myArray[r];
        myArray[r] = t;
      }

      return myArray.slice(0, nb_picks);
    },
    showNumber: function showNumber(positionType) {
      var icons = {
        'DOOR': false,
        'SEAT': true,
        'SEAT_AND_TV': true,
        'BATHROOM': false,
        'TV': false,
        'BED': false,
        'TRUNK': false,
        'VIRTUAL': false,
        'BORDER_RIGHT': false,
        'BORDER_LEFT': false,
        'WHEEL': false,
        'EXTRA_SEAT': true,
        'COFFEE': false,
        'EMPTY': false
      };
      return icons[positionType];
    },
    translateIcon: function translateIcon(positionType) {
      var icons = {
        'DOOR': 'pue',
        'SEAT': 'a',
        'SEAT_AND_TV': 'atv',
        'BATHROOM': 'wc',
        'TV': 'tv',
        'BED': 'cam',
        'TRUNK': 'ca',
        'VIRTUAL': 'vi',
        'BORDER_RIGHT': 'br',
        'BORDER_LEFT': 'bl',
        'WHEEL': 'vol',
        'EXTRA_SEAT': 'peri',
        'COFFEE': 'caf',
        'EMPTY': 'emp'
      };
      return icons[positionType];
    },
    actions: {
      passengerClicked: function passengerClicked(passenger) {
        if (passenger.passengerType === 'INFANT') {
          return;
        }
        this.get('passengers').forEach(function (p) {
          _ember['default'].set(p, 'selected', false);
        });
        this.set('currentPassenger', passenger);
        _ember['default'].set(passenger, 'selected', true);
      },
      positionClicked: function positionClicked(position) {
        var _this4 = this;

        var currentPassenger = this.get('currentPassenger');
        var mode = this.get('mode');
        var selected = position.icon.indexOf('-unselected') < 0;
        var seatName = _ember['default'].get(currentPassenger, mode + 'Seat');
        if (seatName) {
          this.get('trip.positions').forEach(function (rows) {
            rows.forEach(function (row) {
              row.forEach(function (seat) {
                if (seat && seatName === seat.name) {
                  var icon = _this4.translateIcon(seat.type) + '-unselected';
                  _ember['default'].set(seat, 'icon', icon);
                }
              });
            });
          });
        }
        if (currentPassenger && this.get('seats').indexOf(position.type) >= 0 && this.get('edition') && !selected) {
          var icon = this.translateIcon(position.type) + '-selected';
          _ember['default'].set(currentPassenger, mode + 'Seat', position.name);
          _ember['default'].set(position, 'icon', icon);
          _ember['default'].set(currentPassenger, 'selected', false);
          if (currentPassenger.idx + 1 < this.get('passengers').length && this.get('passengers').get(currentPassenger.idx + 1).passengerType !== 'INFANT') {
            this.set('currentPassenger', this.get('passengers').get(currentPassenger.idx + 1));
            this.set('currentPassenger.selected', true);
          } else {
            this.set('currentPassenger', null);
            this.set('currentFloor', 0);
          }
          var allSelected = this.get('passengers').every(function (passenger) {
            return passenger[mode + 'Seat'] != null || passenger.passengerType === 'INFANT';
          });
          if (allSelected) {
            this.sendAction('allSelected', this.get('trip'), this.get('quote'));
          }
        }
      },
      changeFloor: function changeFloor(floor) {
        this.set('currentFloor', floor);
      }
    }
  });
});