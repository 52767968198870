define('ticket-sales-frontend/routes/validate-paypal', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		queryParams: {
			paymentId: {
				refreshModel: true
			},
			token: {
				refreshModel: true
			},
			PayerID: {
				refreshModel: true
			}
		}
	});
});