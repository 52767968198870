define('ticket-sales-frontend/routes/view-ticket-detail', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      resultIndicator: {}
    },
    model: function model(params) {
      return _emberData['default'].PromiseObject.create({
        promise: new Promise(function (resolve) {
          jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/saleData/' + params.id, function (data) {
            if (!data.payed) {
              jQuery('.loading').show();
              setTimeout(function () {
                location.reload();
              }, 5000);
            } else if (data.payment && params.resultIndicator && fbq) {
              fbq('track', 'Purchase', { value: data.payment.amount, currency: 'MXN' });
              console.log('track', data.payment.amount);
            }
            resolve(data);
          });
        })
      });
    }
  });
});