define('ticket-sales-frontend/components/type-ahead', ['exports', 'ember'], function (exports, _ember) {
  var substringMatcher = function substringMatcher(container, key) {
    return function findMatches(q, cb, acb) {
      var fn = container.get('content');

      fn(q).then(function (data) {
        acb(data.map(function (it) {
          var obj = { obj: it };
          obj[key] = it[key];
          return obj;
        }));
      });

      cb([]);
    };
  };

  exports['default'] = _ember['default'].TextField.extend({
    content: null,
    highlight: false,
    hint: true,
    minLength: 0,
    autofocus: true,
    _typeahead: null,
    selection: null,

    focusOut: function focusOut() {
      this.sendAction('targetAction', "focus-out");
    },

    focusIn: function focusIn() {
      this.sendAction('targetAction', "focus-in");
    },

    keyUp: function keyUp() {
      this.sendAction('targetAction', "key-up");
    },

    keyDown: function keyDown() {
      this.sendAction('targetAction', "key-down");
    },

    /* Can add more event catchers here */

    didInsertElement: function didInsertElement() {
      this._super();
      this.initializeTypeahead();
      if (this.get('autofocus') === true) {
        this.$().focus();
      }
    },
    initializeTypeahead: function initializeTypeahead() {
      var that = this,
          t = null,
          options = {
        highlight: this.get('highlight'),
        hint: this.get('hint'),
        minLength: this.get('minLength')
      },
          dataset = this.get('dataset');
      t = this.$().typeahead(options, dataset);
      this.set('_typeahead', t);

      // Set selected object
      t.on('typeahead:selected', function (event, item) {
        _ember['default'].debug("Setting suggestion");
        that.set('selection', item.obj);
      });

      t.on('typeahead:autocompleted', function (event, item) {
        _ember['default'].debug("Setting suggestion");
        that.set('selection', item.obj);
      });
    },
    dataset: (function () {
      return this.loadDataset(this);
    }).property(),
    loadDataset: function loadDataset(content) {
      var name = this.get('name') || 'default',
          key = this.get('displayKey') || 'value';
      return {
        name: name,
        displayKey: key,
        source: substringMatcher(content, key),
        limit: 100
      };
    },
    clearDataset: (function () {
      if (_ember['default'].isBlank(this.get('selection'))) {
        this.$().val('');
      }
    }).observes('selection')
  });
});