define('ticket-sales-frontend/controllers/admin-payments', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {

  var expenseTypes = [{
    name: 'Diesel (contado)'
  }, {
    name: 'Reparación de llantas'
  }, {
    name: 'Estacionamiento'
  }, {
    name: 'Autopistas (crédito)'
  }, {
    name: 'Autopistas (contado)'
  }, {
    name: 'Artículos de limpieza'
  }, {
    name: 'Pensión / Hotel'
  }, {
    name: 'Conferencia telefónica'
  }, {
    name: 'Engrasado / Lavado'
  }, {
    name: 'Refacciones y accesorios'
  }, {
    name: 'Mano de obra reparación'
  }, {
    name: 'Daño a unidad'
  }, {
    name: 'Pasajero olvidado'
  }, {
    name: 'Maleta Extraviada'
  }, {
    name: 'Otros gastos'
  }];

  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    section: 'listing',
    expenses: [],
    startShift: false,
    startSnapshot: false,
    startEnding: false,
    amount: 0,
    expenseTypes: expenseTypes,
    tripDetail: null,
    checklist: {
      hasAllPlaces: false,
      hasAllStamps: false,
      packageArrived: false
    },
    selectedEnding: {
      name: null
    },
    dieselTotal: _ember['default'].computed('tripDetail.trips.@each.dieselLiters', 'tripDetail.dieselCost', function () {
      var _this = this;

      var total = 0;
      this.get('tripDetail.trips').forEach(function (trip) {
        if (trip.dieselLiters) {
          total += _this.get('tripDetail.dieselCost') * trip.dieselLiters;
        }
      });
      return total;
    }),
    driver2Total: _ember['default'].computed('tripDetail', 'expenses.@each.amount', 'expenses.@each.type', function () {
      return this.get('driver2Earnings') - this.get('driver2ExpensesSubtotal') - this.get('driver2Loans') - (this.get('tripDetail.driver2NominalDiscount.weekAmount') || 0) - (this.get('tripDetail.driver2InsuranceDiscount.weekAmount') || 0);;
    }),
    driver1Total: _ember['default'].computed('tripDetail', 'expenses.@each.amount', 'expenses.@each.type', function () {
      return this.get('driver1Earnings') - this.get('driver1ExpensesSubtotal') - this.get('driver1Loans') - (this.get('tripDetail.driver1NominalDiscount.weekAmount') || 0) - (this.get('tripDetail.driver1InsuranceDiscount.weekAmount') || 0);;
    }),
    driver1Earnings: _ember['default'].computed('tripDetail', 'expenses.@each.amount', 'expenses.@each.type', function () {
      var percentage = 0.08;
      if (!this.get('tripDetail.driver2')) {
        percentage = 0.10;
      }
      var baggageTotal = this.get('tripDetail.baggageData').map(function (t) {
        return t.paymentPrice;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      var total = this.get('tripDetail.tripData.tickets').map(function (t) {
        return t.payment;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      var returnValue = (total + baggageTotal) * percentage;
      if (returnValue < 0) {
        return 0;
      }
      return returnValue;
    }),
    driver2Earnings: _ember['default'].computed('tripDetail', function () {
      if (!this.get('tripDetail.driver2')) {
        return 0;
      }
      var baggageTotal = this.get('tripDetail.baggageData').map(function (t) {
        return t.paymentPrice;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      var total = this.get('tripDetail.tripData.tickets').map(function (t) {
        return t.payment;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      var returnValue = (total + baggageTotal) * 0.08;
      if (returnValue < 0) {
        return 0;
      }
      return returnValue;
    }),
    driver1Loans: _ember['default'].computed('tripDetail', 'expenses.@each.amount', 'expenses.@each.type', function () {
      var loans = this.get('tripDetail.driver1.loans');
      if (!loans) {
        loans = [];
      }
      return loans.map(function (l) {
        var discount;
        if (l.type === 'NUMBER') {
          discount = l.number;
        } else {
          discount = l.loanAmount * l.number / 100;
        }
        if (discount > l.missingAmount) {
          discount = l.missingAmount;
        }
        return discount;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
    }),
    driver2Loans: _ember['default'].computed('tripDetail', 'expenses.@each.amount', 'expenses.@each.type', function () {
      var loans = this.get('tripDetail.driver2.loans');
      if (!loans) {
        loans = [];
      }
      return loans.map(function (l) {
        var discount;
        if (l.type === 'NUMBER') {
          discount = l.number;
        } else {
          discount = l.loanAmount * l.number / 100;
        }
        return discount;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
    }),
    driver1Expenses: _ember['default'].computed('tripDetail', 'expenses.@each.amount', 'expenses.@each.type', function () {
      // var percentage = 0.08;
      // if(!this.get('tripDetail.driver2')) {
      //   percentage = 0.13;
      // }
      // let discount = this.get('tripDetail.driver1.loans').map(l => {
      //   var discount;
      //   if(l.type === 'NUMBER') {
      //     discount = (l.loanAmount / l.number);
      //   } else {
      //     discount = (l.loanAmount * l.number / 100);
      //   }
      //   return discount;
      // }).reduce(function (a, b) {
      //   return a + b;
      // }, 0);
      // let baggageTotal = this.get('tripDetail.baggageData').map(t => t.paymentPrice).reduce(function (a, b) {
      //   return a + b;
      // }, 0);
      // let total = this.get('tripDetail.tripData.tickets').map(t => t.payment).reduce(function (a, b) {
      //   return a + b;
      // }, 0);
      // let advanceTotal = this.get('tripDetail.advances').map(a => a.amount).reduce(function (a, b) {
      //   return a + b;
      // }, 0);
      var expenseTotal = this.get('expenses').filter(function (e) {
        return e.type && e.type.indexOf('crédito') < 0;
      }).map(function (e) {
        return parseInt(e.amount, 10);
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      return expenseTotal;
    }),
    driver2Expenses: 0,
    trips: _ember['default'].computed('selectedEnding.name', function () {
      var ending = this.get('selectedEnding.name');
      return _emberData['default'].PromiseArray.create({
        promise: jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/endings?stopOff=' + this.get('selectedEnding.name')).then(function (data) {
          return data;
        })
      });
    }),
    driver1ExpensesSubtotal: _ember['default'].computed('tripDetail', 'expenses.@each.amount', 'expenses.@each.type', function () {
      return this.get('driver1ExpensesAdvance') - this.get('driver1Expenses');
    }),
    driver2ExpensesSubtotal: 0,
    driver1ExpensesAdvance: _ember['default'].computed('tripDetail', 'expenses.@each.amount', 'expenses.@each.type', function () {
      var advanceTotal = this.get('tripDetail.advances').map(function (a) {
        return a.amount;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      return advanceTotal;
    }),
    paymentPossible: _ember['default'].computed('allow', 'dieselTotal', function () {
      return this.get('allow') && this.get('dieselTotal') >= 0;
    }),
    driver2ExpensesAdvance: 0,
    actions: {
      startShift: function startShift() {
        var _this2 = this;

        var username = this.get('session.data.profile.username');
        localforage.getItem('terminalData').then(function (data) {
          _this2.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/payment/startShift?terminalId=' + data.id + '&employeeId=' + username).then(function (start) {
            _this2.set('model.status', true);
            location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/payment/downloadStartShift/' + data.id + '.pdf?timeZone=' + 'America/Mexico_City';
          });
        });
      },
      startSnapshot: function startSnapshot() {
        this.set('startSnapshot', true);
      },
      saveSnapshot: function saveSnapshot() {
        var _this3 = this;

        localforage.getItem('terminalData').then(function (data) {
          console.log(data);
          _this3.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/payment/recordSnapshot?terminalId=' + data.id + '&amount=' + _this3.get('amount')).then(function (close) {
            _this3.set('startSnapshot', false);
            location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/payment/downloadRecordSnapshot/' + data.id + '.pdf?timeZone=' + 'America/Mexico_City';
          });
        });
      },
      startClose: function startClose() {
        this.set('startEnding', true);
      },
      closeShift: function closeShift() {
        var _this4 = this;

        localforage.getItem('terminalData').then(function (data) {
          _this4.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/payment/closeShift?terminalId=' + data.id + '&amount=' + _this4.get('amount')).then(function (close) {
            _this4.set('model.status', false);
            location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/payment/downloadCloseShift/' + data.id + '.pdf?timeZone=' + 'America/Mexico_City';
          });
        });
      },
      addExpense: function addExpense() {
        this.get('expenses').pushObject({ receipt: false });
      },
      removeExpense: function removeExpense(expense) {
        this.get('expenses').removeObject(expense);
      },
      saveTemporal: function saveTemporal(tripDetail, expenses, checklist) {
        var saveButton = Ladda.create($('#saveButton').get(0));
        saveButton.start();
        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/savePaymentSnapshot', {
          method: 'POST',
          data: JSON.stringify({
            tripId: tripDetail.id,
            expenses: expenses,
            checklist: checklist,
            trips: this.get('tripDetail.trips'),
            dieselCost: this.get('tripDetail.dieselCost'),
            driver1Id: this.get('tripDetail.driver1.id'),
            driver2Id: this.get('tripDetail.driver2.id'),
            driver1Earnings: this.get('driver1Earnings'),
            driver2Earnings: this.get('driver2Earnings'),
            driver1ExpensesAdvance: this.get('driver1ExpensesAdvance'),
            driver2ExpensesAdvance: this.get('driver2ExpensesAdvance'),
            driver1Expenses: this.get('driver1Expenses'),
            driver2Expenses: this.get('driver2Expenses'),
            driver1Loans: this.get('driver1Loans'),
            driver2Loans: this.get('driver2Loans'),
            driver1Amount: this.get('driver1Total'),
            driver2Amount: this.get('driver2Total')
          }),
          contentType: 'application/json; charset=utf-8'
        }).then(function (data) {
          console.log(data);
          saveButton.stop();
        }, function (error) {
          console.error(error);
          saveButton.stop();
        });
      },
      save: function save(tripDetail, expenses, checklist) {
        var _this5 = this;

        var found = false;
        expenses.forEach(function (e) {
          if (e.comments === 'Diesel, automático') {
            found = true;
            _ember['default'].set(e, 'amount', _this5.get('dieselTotal'));
          }
        });
        if (!found) {
          expenses.push({
            receipt: false,
            comments: 'Diesel, automático',
            amount: this.get('dieselTotal'),
            type: 'Diesel (crédito)'
          });
        }
        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/savePayment', {
          method: 'POST',
          data: JSON.stringify({
            tripId: tripDetail.id,
            expenses: expenses,
            checklist: checklist,
            dieselCost: this.get('tripDetail.dieselCost'),
            trips: this.get('tripDetail.trips'),
            driver1Id: this.get('tripDetail.driver1.id'),
            driver2Id: this.get('tripDetail.driver2.id'),
            driver1Earnings: this.get('driver1Earnings'),
            driver2Earnings: this.get('driver2Earnings'),
            driver1ExpensesAdvance: this.get('driver1ExpensesAdvance'),
            driver2ExpensesAdvance: this.get('driver2ExpensesAdvance'),
            driver1Expenses: this.get('driver1Expenses'),
            driver2Expenses: this.get('driver2Expenses'),
            driver1Loans: this.get('driver1Loans'),
            driver2Loans: this.get('driver2Loans'),
            driver1Amount: this.get('driver1Total'),
            driver2Amount: this.get('driver2Total')
          }),
          contentType: 'application/json; charset=utf-8'
        }).then(function () {
          _this5.set('selectedEnding.name', null);
          _this5.set('section', 'listing');
          location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/paymentReport/' + tripDetail.id + '.pdf?timeZone=' + 'America/Mexico_City';
        });
      },
      showTrip: function showTrip(trip) {
        var _this6 = this;

        this.set('expenses', []);
        localforage.getItem('terminalData').then(function (data) {
          _this6.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/terminal/' + data.id).then(function (terminalData) {
            _this6.set('expenseTypes', expenseTypes.filter(function (type) {
              return type.name != 'Reparación de llantas' || terminalData.officeName == 'Tampico';
            }));
          });
        });
        jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/groups/' + trip.id).then(function (data) {
          var allow = true;
          console.log(data);
          if (data.expenses) {
            _this6.set('expenses', data.expenses);
          }
          if (data.stopControlData) {
            for (var stop in data.stopControlData) {
              if (data.stopControlData.hasOwnProperty(stop)) {
                if (!data.stopControlData[stop].visited) {
                  allow = false;
                  break;
                }
              }
            }
          }
          data.tripData.tickets = data.tripData.tickets.filter(function (ticket) {
            return ticket.status === 'USED';
          });

          _this6.set('allow', allow);
          _this6.set('section', 'detail');
          _this6.set('tripDetail', data);
        });
      }
    }
  });
});