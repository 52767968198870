define('ticket-sales-frontend/routes/admin-dashboard-reports', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    session: _ember['default'].inject.service('session'),
    getTerminalData: function getTerminalData(obj) {
      var _this = this;

      return _emberData['default'].PromiseObject.create({
        promise: new Promise(function (resolve) {
          localforage.getItem('terminalData').then(function (data) {
            _this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/salesTerminals').then(function (terminals) {
              _this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/officeLocations?size=100').then(function (locations) {
                obj.terminals = terminals._embedded.salesTerminals;
                obj.offices = locations._embedded.officeLocations;
                obj.terminals.sort(function (a, b) {
                  return a.terminalName.localeCompare(b.terminalName);
                });
                obj.offices.sort(function (a, b) {
                  return a.name.localeCompare(b.name);
                });
                if (data && data.id) {
                  _this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/terminal/' + data.id).then(function (terminalData) {
                    obj.terminal = terminalData;
                    resolve(obj);
                  });
                } else {
                  resolve(obj);
                }
              });
            });
          });
        })
      });
    },
    model: function model() {
      return this.getTerminalData({});
    },
    actions: {
      dashboard: function dashboard() {
        console.log('Hola mundo', this.get('authorizedRequest').ajax);
      }
    }
  });
});