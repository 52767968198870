define('ticket-sales-frontend/controllers/validate-paypal', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
	exports['default'] = _ember['default'].Controller.extend({
		queryParams: ['paymentId', 'token', 'PayerID'],
		paymentId: null,
		token: null,
		PayerID: null,
		init: function init() {
			var _this = this;

			this._super();
			_ember['default'].run.scheduleOnce('afterRender', this, function () {
				jQuery('.loading').show();
				_this.confirmPaypal().then(function (data) {
					jQuery('.loading').hide();
					_this.transitionToRoute('finishedPayment', data.shortId);
				});
			});
		},
		confirmPaypal: function confirmPaypal() {
			var params = {
				paymentId: this.get('paymentId'),
				token: this.get('token'),
				PayerID: this.get('PayerID')
			};
			return _emberData['default'].PromiseObject.create({
				promise: jQuery.ajax({
					url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/confirmPaypal',
					data: params,
					method: 'GET'
				})
			});
		}
	});
});