define('ticket-sales-frontend/routes/sale-points', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _emberData['default'].PromiseObject.create({
        promise: jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/offices'
        })
      });
    }
  });
});