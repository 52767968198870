define('ticket-sales-frontend/controllers/stop-checker', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    selectedStop: {
      name: ''
    },
    mustRefresh: false,
    missingStops: _ember['default'].computed('selectedStop.name', 'mustRefresh', function () {
      return _emberData['default'].PromiseArray.create({
        promise: jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/missingStops/' + this.get('selectedStop.name')).then(function (data) {
          return data;
        })
      });
    }),
    actions: {
      visited: function visited(id) {
        var _this = this;

        this.set('mustRefresh', false);
        jQuery.post(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/missingStops/' + id).then(function (data) {
          console.log('hola');
          _this.set('mustRefresh', true);
        });
      }
    }
  });
});