define('ticket-sales-frontend/controllers/trip-dashboard', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    step: 'begin',
    init: function init() {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        this.updateCall();
      });
    },
    updateCall: function updateCall() {
      var _this = this;

      if (this.get('step') === 'begin') {
        jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/routes/stopsSchedule/' + this.get('model').id).then(function (data) {
          _this.set('allStops', data);
          _this.set('stops', _this.get('allStops').slice(0, 15));
          _this.set('step', 'next');
          _this.set('current', 15);
        });
      } else if (this.get('step') === 'next') {
        var start = this.get('current');
        this.set('stops', this.get('allStops').slice(start, start + 15));
        if (start + 15 > this.get('allStops').length) {
          this.set('step', 'begin');
        }
      }
      setTimeout(function () {
        _this.updateCall();
      }, 15000);
    }
  });
});