define('ticket-sales-frontend/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'ember', 'ticket-sales-frontend/config/environment'], function (exports, _emberSimpleAuthAuthenticatorsOauth2PasswordGrant, _ember, _ticketSalesFrontendConfigEnvironment) {
	var isEmpty = _ember['default'].isEmpty;
	exports['default'] = _emberSimpleAuthAuthenticatorsOauth2PasswordGrant['default'].extend({
		serverTokenEndpoint: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/oauth/token',
		clientId: 'clientapp',
		clientSecret: '123456',
		makeRequest: function makeRequest(url, data) {
			var options = {
				url: url,
				data: data,
				type: 'POST',
				dataType: 'json',
				contentType: 'application/x-www-form-urlencoded'

			};
			var clientId = this.get('clientId');
			var clientSecret = this.get('clientSecret');

			if (!isEmpty(clientId)) {
				var base64ClientId = window.btoa(clientId.concat(':').concat(clientSecret));
				_ember['default'].merge(options, {
					headers: {
						Authorization: 'Basic ' + base64ClientId
					}
				});
			}

			return _ember['default'].$.ajax(options);
		}
	});
});