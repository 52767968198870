define("ticket-sales-frontend/templates/ticket-dashboard", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 14
                },
                "end": {
                  "line": 50,
                  "column": 14
                }
              },
              "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-md-12");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "ticket-booth-quotes", [], ["quotes", ["subexpr", "@mut", [["get", "model.quotes.departureQuotes", ["loc", [null, [48, 47], [48, 75]]]]], [], []], "selectQuoteAction", "selectQuote"], ["loc", [null, [48, 18], [48, 109]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 14
                },
                "end": {
                  "line": 55,
                  "column": 14
                }
              },
              "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-md-12");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element25 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element25, 1, 1);
              morphs[1] = dom.createMorphAt(element25, 3, 3);
              return morphs;
            },
            statements: [["inline", "ticket-search", [], ["showPeople", false, "searchAction", "searchTrip", "defaultOrigin", ["subexpr", "@mut", [["get", "model.terminal.stopOffName", ["loc", [null, [52, 91], [52, 117]]]]], [], []], "register-as", ["subexpr", "@mut", [["get", "ticketSearch", ["loc", [null, [52, 130], [52, 142]]]]], [], []]], ["loc", [null, [52, 18], [52, 144]]]], ["inline", "ticket-booth-quotes", [], ["quotes", ["subexpr", "@mut", [["get", "searchDepartures", ["loc", [null, [53, 47], [53, 63]]]]], [], []], "selectQuoteAction", "selectQuote"], ["loc", [null, [53, 18], [53, 97]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 12
                },
                "end": {
                  "line": 62,
                  "column": 12
                }
              },
              "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "href", "");
              dom.setAttribute(el1, "class", "btn btn-info full-width");
              var el2 = dom.createTextNode("Registrar precorte");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element24 = dom.childAt(fragment, [3]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createElementMorph(element24);
              return morphs;
            },
            statements: [["inline", "input", [], ["class", "form-control", "value", ["subexpr", "@mut", [["get", "booth.amount", ["loc", [null, [60, 49], [60, 61]]]]], [], []], "type", "text", "placeholder", "Monto a sacar"], ["loc", [null, [60, 14], [60, 103]]]], ["element", "action", ["saveRecordSnapshot", ["get", "booth", ["loc", [null, [61, 52], [61, 57]]]]], [], ["loc", [null, [61, 22], [61, 59]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 10
              },
              "end": {
                "line": 63,
                "column": 10
              }
            },
            "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("ul");
            dom.setAttribute(el2, "class", "nav nav-tabs");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("li");
            dom.setAttribute(el3, "role", "presentation");
            var el4 = dom.createElement("a");
            dom.setAttribute(el4, "href", "");
            var el5 = dom.createTextNode("Búsqueda");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("\n                <li role=\"presentation\" class=\"{{if (eq saleType 'local') 'active'}}\"><a href {{action 'selectLocal'}}>Venta local</a></li>\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "href", "");
            dom.setAttribute(el1, "class", "btn btn-info");
            var el2 = dom.createTextNode("Precorte");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "href", "");
            dom.setAttribute(el1, "class", "btn btn-info");
            var el2 = dom.createTextNode("Cierre de caja");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element26 = dom.childAt(fragment, [1, 1, 1]);
            var element27 = dom.childAt(element26, [0]);
            var element28 = dom.childAt(fragment, [5]);
            var element29 = dom.childAt(fragment, [7]);
            var morphs = new Array(6);
            morphs[0] = dom.createAttrMorph(element26, 'class');
            morphs[1] = dom.createElementMorph(element27);
            morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
            morphs[3] = dom.createElementMorph(element28);
            morphs[4] = dom.createElementMorph(element29);
            morphs[5] = dom.createMorphAt(fragment, 9, 9, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "eq", [["get", "saleType", ["loc", [null, [39, 56], [39, 64]]]], "search"], [], ["loc", [null, [39, 52], [39, 74]]]], "active"], [], ["loc", [null, [39, 47], [39, 85]]]]]]], ["element", "action", ["selectSearch"], [], ["loc", [null, [39, 95], [39, 120]]]], ["block", "if", [["subexpr", "eq", [["get", "saleType", ["loc", [null, [46, 24], [46, 32]]]], "local"], [], ["loc", [null, [46, 20], [46, 41]]]]], [], 0, 1, ["loc", [null, [46, 14], [55, 21]]]], ["element", "action", ["recordSnapshot"], [], ["loc", [null, [57, 20], [57, 47]]]], ["element", "action", ["endShift"], [], ["loc", [null, [58, 20], [58, 41]]]], ["block", "if", [["get", "startSnapshot", ["loc", [null, [59, 18], [59, 31]]]]], [], 2, null, ["loc", [null, [59, 12], [62, 19]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 120,
                    "column": 22
                  },
                  "end": {
                    "line": 122,
                    "column": 22
                  }
                },
                "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "class", "btn btn-danger");
                var el2 = dom.createTextNode("Eliminar pasajero");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element14 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element14);
                return morphs;
              },
              statements: [["element", "action", ["deletePassenger", ["get", "passenger", ["loc", [null, [121, 54], [121, 63]]]]], [], ["loc", [null, [121, 27], [121, 65]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 102,
                  "column": 16
                },
                "end": {
                  "line": 125,
                  "column": 16
                }
              },
              "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element15, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element15, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element15, [5]), 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element15, [7]), 1, 1);
              return morphs;
            },
            statements: [["inline", "input", [], ["class", "form-control", "value", ["subexpr", "@mut", [["get", "passenger.name", ["loc", [null, [105, 57], [105, 71]]]]], [], []], "type", "text", "placeholder", "Nombre"], ["loc", [null, [105, 22], [105, 106]]]], ["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "passengerTypes", ["loc", [null, [108, 46], [108, 60]]]]], [], []], "class", "form-control", "prompt", "Selecciona un tipo de pasajero", "value", ["subexpr", "@mut", [["get", "passenger.passengerType", ["loc", [null, [111, 30], [111, 53]]]]], [], []], "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "passenger.passengerType", ["loc", [null, [112, 46], [112, 69]]]]], [], ["loc", [null, [112, 41], [112, 70]]]]], [], ["loc", [null, [112, 33], [112, 71]]]], "optionValuePath", "content.id", "optionLabelPath", "content.name"], ["loc", [null, [108, 22], [114, 56]]]], ["inline", "input", [], ["class", "form-control", "value", ["subexpr", "@mut", [["get", "passenger.comments", ["loc", [null, [117, 57], [117, 75]]]]], [], []], "type", "text", "placeholder", "Observaciones"], ["loc", [null, [117, 22], [117, 117]]]], ["block", "if", [["get", "passenger.delete", ["loc", [null, [120, 28], [120, 44]]]]], [], 0, null, ["loc", [null, [120, 22], [122, 29]]]]],
            locals: ["passenger"],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 137,
                  "column": 14
                },
                "end": {
                  "line": 144,
                  "column": 14
                }
              },
              "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" a ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [1]);
              var element13 = dom.childAt(element12, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element13, 0, 0);
              morphs[1] = dom.createMorphAt(element13, 2, 2);
              morphs[2] = dom.createMorphAt(dom.childAt(element12, [3]), 0, 0);
              morphs[3] = dom.createMorphAt(dom.childAt(element12, [5]), 0, 0);
              morphs[4] = dom.createMorphAt(dom.childAt(element12, [7]), 0, 0);
              return morphs;
            },
            statements: [["content", "quote.quote.origin.name", ["loc", [null, [139, 22], [139, 49]]]], ["content", "quote.quote.destination.name", ["loc", [null, [139, 52], [139, 84]]]], ["inline", "format-money", [["get", "quote.subtotalPrice", ["loc", [null, [140, 37], [140, 56]]]]], [], ["loc", [null, [140, 22], [140, 58]]]], ["inline", "format-money", [["get", "quote.taxes", ["loc", [null, [141, 37], [141, 48]]]]], [], ["loc", [null, [141, 22], [141, 50]]]], ["inline", "format-money", [["get", "quote.totalPrice", ["loc", [null, [142, 37], [142, 53]]]]], [], ["loc", [null, [142, 22], [142, 55]]]]],
            locals: ["quote"],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 147,
                  "column": 12
                },
                "end": {
                  "line": 156,
                  "column": 12
                }
              },
              "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h3");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" a ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("strong");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element11, 0, 0);
              morphs[1] = dom.createMorphAt(element11, 2, 2);
              morphs[2] = dom.createMorphAt(dom.childAt(element11, [4]), 0, 0);
              morphs[3] = dom.createMorphAt(element11, 6, 6);
              morphs[4] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["content", "quote.quote.origin.name", ["loc", [null, [148, 16], [148, 43]]]], ["content", "quote.quote.destination.name", ["loc", [null, [148, 46], [148, 78]]]], ["content", "quote.quote.serviceLevel", ["loc", [null, [148, 87], [148, 115]]]], ["inline", "moment-format", [["get", "quote.quote.departingDate", ["loc", [null, [148, 141], [148, 166]]]], "llll"], [], ["loc", [null, [148, 125], [148, 175]]]], ["inline", "ticket-seats", [], ["booth", true, "allSelected", "seatsSelected", "mode", ["subexpr", "@mut", [["get", "quote.quote.mode", ["loc", [null, [152, 21], [152, 37]]]]], [], []], "trip", ["subexpr", "@mut", [["get", "quote.trip", ["loc", [null, [153, 21], [153, 31]]]]], [], []], "quote", ["subexpr", "@mut", [["get", "quote.quote", ["loc", [null, [154, 22], [154, 33]]]]], [], []], "passengers", ["subexpr", "@mut", [["get", "passengers", ["loc", [null, [155, 27], [155, 37]]]]], [], []]], ["loc", [null, [149, 14], [155, 39]]]]],
            locals: ["quote"],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 159,
                  "column": 14
                },
                "end": {
                  "line": 161,
                  "column": 14
                }
              },
              "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "href", "");
              dom.setAttribute(el1, "class", "btn btn-success");
              var el2 = dom.createTextNode("Continuar");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element10);
              return morphs;
            },
            statements: [["element", "action", ["selectPayment"], [], ["loc", [null, [160, 46], [160, 72]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 10
              },
              "end": {
                "line": 163,
                "column": 10
              }
            },
            "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h2");
            var el2 = dom.createTextNode("Pasajeros");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("table");
            dom.setAttribute(el1, "class", "table table-condensed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("thead");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tr");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Tipo");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Vendidos");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Máximo");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("tbody");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tr");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("td");
            var el5 = dom.createTextNode("Adultos mayores");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("td");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("td");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tr");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("td");
            var el5 = dom.createTextNode("Estudiantes");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("td");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("td");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tr");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("td");
            var el5 = dom.createTextNode("Reservados");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("td");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("td");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("table");
            dom.setAttribute(el1, "class", "table table-condensed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("thead");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tr");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Nombre");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Tipo de pasajero");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Observaciones");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("tbody");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tr");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("td");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("td");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("a");
            dom.setAttribute(el5, "class", "btn btn-info");
            var el6 = dom.createTextNode("Agregar pasajero");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h2");
            var el2 = dom.createTextNode("Costo");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("table");
            dom.setAttribute(el1, "class", "table table-bordered");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("tbody");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "pull-right");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "href", "");
            dom.setAttribute(el2, "class", "btn btn-danger");
            var el3 = dom.createTextNode("Regresar");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [3, 3]);
            var element17 = dom.childAt(element16, [1]);
            var element18 = dom.childAt(element16, [3]);
            var element19 = dom.childAt(element16, [5]);
            var element20 = dom.childAt(fragment, [5, 3]);
            var element21 = dom.childAt(element20, [3, 3, 1]);
            var element22 = dom.childAt(fragment, [13]);
            var element23 = dom.childAt(element22, [1]);
            var morphs = new Array(12);
            morphs[0] = dom.createMorphAt(dom.childAt(element17, [3]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element17, [5]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element18, [3]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element18, [5]), 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(element19, [3]), 0, 0);
            morphs[5] = dom.createMorphAt(dom.childAt(element19, [5]), 0, 0);
            morphs[6] = dom.createMorphAt(element20, 1, 1);
            morphs[7] = dom.createElementMorph(element21);
            morphs[8] = dom.createMorphAt(dom.childAt(fragment, [9, 1]), 1, 1);
            morphs[9] = dom.createMorphAt(fragment, 11, 11, contextualElement);
            morphs[10] = dom.createElementMorph(element23);
            morphs[11] = dom.createMorphAt(element22, 3, 3);
            return morphs;
          },
          statements: [["content", "sale.departureTrip.olderAdultCount", ["loc", [null, [77, 22], [77, 60]]]], ["content", "sale.departureTrip.maxOlderAdults", ["loc", [null, [78, 22], [78, 59]]]], ["content", "sale.departureTrip.studentCount", ["loc", [null, [82, 22], [82, 57]]]], ["content", "sale.departureTrip.maxStudents", ["loc", [null, [83, 22], [83, 56]]]], ["content", "sale.departureTrip.reservedCount", ["loc", [null, [87, 22], [87, 58]]]], ["content", "sale.departureTrip.maxReserved", ["loc", [null, [88, 22], [88, 56]]]], ["block", "each", [["get", "passengers", ["loc", [null, [102, 24], [102, 34]]]]], [], 0, null, ["loc", [null, [102, 16], [125, 25]]]], ["element", "action", ["addPassenger"], [], ["loc", [null, [129, 44], [129, 69]]]], ["block", "each", [["get", "quotes", ["loc", [null, [137, 22], [137, 28]]]]], [], 1, null, ["loc", [null, [137, 14], [144, 23]]]], ["block", "each", [["get", "quotes", ["loc", [null, [147, 20], [147, 26]]]]], [], 2, null, ["loc", [null, [147, 12], [156, 21]]]], ["element", "action", ["returnToQuotes"], [], ["loc", [null, [158, 45], [158, 72]]]], ["block", "if", [["get", "sufficientSeats", ["loc", [null, [159, 20], [159, 35]]]]], [], 3, null, ["loc", [null, [159, 14], [161, 21]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 168,
                  "column": 12
                },
                "end": {
                  "line": 178,
                  "column": 12
                }
              },
              "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h3");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" a ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("strong");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element9, 0, 0);
              morphs[1] = dom.createMorphAt(element9, 2, 2);
              morphs[2] = dom.createMorphAt(dom.childAt(element9, [4]), 0, 0);
              morphs[3] = dom.createMorphAt(element9, 6, 6);
              morphs[4] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["content", "quote.quote.origin.name", ["loc", [null, [169, 16], [169, 43]]]], ["content", "quote.quote.destination.name", ["loc", [null, [169, 46], [169, 78]]]], ["content", "quote.quote.serviceLevel", ["loc", [null, [169, 87], [169, 115]]]], ["inline", "moment-format", [["get", "quote.quote.departingDate", ["loc", [null, [169, 141], [169, 166]]]], "llll"], [], ["loc", [null, [169, 125], [169, 175]]]], ["inline", "ticket-seats", [], ["booth", true, "allSelected", "seatsSelected", "edition", false, "mode", ["subexpr", "@mut", [["get", "quote.quote.mode", ["loc", [null, [174, 21], [174, 37]]]]], [], []], "trip", ["subexpr", "@mut", [["get", "quote.trip", ["loc", [null, [175, 21], [175, 31]]]]], [], []], "quote", ["subexpr", "@mut", [["get", "quote.quote", ["loc", [null, [176, 22], [176, 33]]]]], [], []], "passengers", ["subexpr", "@mut", [["get", "passengers", ["loc", [null, [177, 27], [177, 37]]]]], [], []]], ["loc", [null, [170, 14], [177, 39]]]]],
            locals: ["quote"],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 180,
                  "column": 12
                },
                "end": {
                  "line": 182,
                  "column": 12
                }
              },
              "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "btn btn-info");
              var el2 = dom.createTextNode("Reservar");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element8);
              return morphs;
            },
            statements: [["element", "action", ["reserve", ["get", "sale", ["loc", [null, [181, 55], [181, 59]]]], ["get", "passengers", ["loc", [null, [181, 60], [181, 70]]]]], [], ["loc", [null, [181, 36], [181, 72]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 164,
                "column": 10
              },
              "end": {
                "line": 183,
                "column": 10
              }
            },
            "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("\n              Total a pagar: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "format-money", [["get", "sale.totalPrice", ["loc", [null, [166, 44], [166, 59]]]]], [], ["loc", [null, [166, 29], [166, 61]]]], ["block", "each", [["get", "quotes", ["loc", [null, [168, 20], [168, 26]]]]], [], 0, null, ["loc", [null, [168, 12], [178, 21]]]], ["inline", "ticket-booth-payment", [], ["change", true, "passengers", ["subexpr", "@mut", [["get", "passengers", ["loc", [null, [179, 58], [179, 68]]]]], [], []], "sale", ["subexpr", "@mut", [["get", "sale", ["loc", [null, [179, 74], [179, 78]]]]], [], []], "paymentReceived", "paymentReceived", "register-as", ["subexpr", "@mut", [["get", "paymentComponent", ["loc", [null, [179, 125], [179, 141]]]]], [], []], "returnReceived", "returnToBus"], ["loc", [null, [179, 12], [179, 172]]]], ["block", "if", [["get", "canReserve", ["loc", [null, [180, 18], [180, 28]]]]], [], 1, null, ["loc", [null, [180, 12], [182, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 184,
                "column": 10
              },
              "end": {
                "line": 188,
                "column": 10
              }
            },
            "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            Código de confirmación ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("strong");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "btn btn-success");
            dom.setAttribute(el1, "href", "/ticket-dashboard");
            var el2 = dom.createTextNode("Nueva venta");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "reservationCode", ["loc", [null, [185, 43], [185, 62]]]]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 193,
                  "column": 14
                },
                "end": {
                  "line": 199,
                  "column": 14
                }
              },
              "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "alert alert-danger");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
              return morphs;
            },
            statements: [["content", "discountError", ["loc", [null, [196, 18], [196, 35]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 189,
                "column": 10
              },
              "end": {
                "line": 214,
                "column": 10
              }
            },
            "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("form");
            dom.setAttribute(el1, "autocomplete", "off");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h1");
            var el3 = dom.createTextNode("Se requiere aprobación para password");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2, "type", "password");
            dom.setAttribute(el2, "style", "display: none");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("dl");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("dt");
            var el4 = dom.createTextNode("Usuario");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("dd");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("dt");
            var el4 = dom.createTextNode("Password");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("dd");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "pull-right");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "href", "");
            dom.setAttribute(el3, "class", "btn btn-success");
            var el4 = dom.createTextNode("Continuar");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var element6 = dom.childAt(element5, [7]);
            var element7 = dom.childAt(element5, [9, 1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(element5, 5, 5);
            morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element6, [7]), 1, 1);
            morphs[3] = dom.createElementMorph(element7);
            return morphs;
          },
          statements: [["block", "if", [["get", "discountError", ["loc", [null, [193, 20], [193, 33]]]]], [], 0, null, ["loc", [null, [193, 14], [199, 21]]]], ["inline", "input", [], ["class", "form-control", "value", ["subexpr", "@mut", [["get", "discount.username", ["loc", [null, [203, 55], [203, 72]]]]], [], []], "type", "text", "placeholder", "Nombre de usuario", "autocomplete", "off"], ["loc", [null, [203, 20], [203, 137]]]], ["inline", "input", [], ["class", "form-control", "value", ["subexpr", "@mut", [["get", "discount.password", ["loc", [null, [207, 55], [207, 72]]]]], [], []], "type", "password", "placeholder", "Clave", "autocomplete", "generated-token"], ["loc", [null, [207, 20], [207, 141]]]], ["element", "action", ["checkDiscountPassword"], [], ["loc", [null, [211, 50], [211, 84]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 223,
                    "column": 18
                  },
                  "end": {
                    "line": 225,
                    "column": 18
                  }
                },
                "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    Caja\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 225,
                    "column": 18
                  },
                  "end": {
                    "line": 227,
                    "column": 18
                  }
                },
                "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    Internet\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 234,
                    "column": 14
                  },
                  "end": {
                    "line": 236,
                    "column": 14
                  }
                },
                "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "class", "btn btn-info");
                var el2 = dom.createTextNode("Descargar factura");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element3, 'href');
                return morphs;
              },
              statements: [["attribute", "href", ["concat", ["https://apisales.turismoenomnibus.com.mx/sale/downloadBill/", ["get", "finishedSale.shortId", ["loc", [null, [235, 107], [235, 127]]]], "?timeZone=", ["get", "tz", ["loc", [null, [235, 141], [235, 143]]]]]]]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 249,
                    "column": 16
                  },
                  "end": {
                    "line": 258,
                    "column": 16
                  }
                },
                "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                var el4 = dom.createTextNode("Descargar");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var element2 = dom.childAt(element1, [11, 1]);
                var morphs = new Array(6);
                morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
                morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
                morphs[3] = dom.createMorphAt(dom.childAt(element1, [7]), 1, 1);
                morphs[4] = dom.createMorphAt(dom.childAt(element1, [9]), 1, 1);
                morphs[5] = dom.createAttrMorph(element2, 'href');
                return morphs;
              },
              statements: [["content", "ticket.name", ["loc", [null, [251, 25], [251, 40]]]], ["content", "ticket.seat", ["loc", [null, [252, 25], [252, 40]]]], ["content", "ticket.startingStop", ["loc", [null, [253, 25], [253, 48]]]], ["content", "ticket.endingStop", ["loc", [null, [254, 25], [254, 46]]]], ["content", "ticket.ticketId", ["loc", [null, [255, 25], [255, 44]]]], ["attribute", "href", ["concat", ["https://apisales.turismoenomnibus.com.mx/sale/downloadTicket/", ["get", "ticket.ticketId", ["loc", [null, [256, 97], [256, 112]]]], "?timeZone=", ["get", "tz", ["loc", [null, [256, 126], [256, 128]]]]]]]],
              locals: ["ticket"],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 216,
                  "column": 12
                },
                "end": {
                  "line": 261,
                  "column": 12
                }
              },
              "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h1");
              var el2 = dom.createTextNode("Muchas gracias por tu compra");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("dl");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("dt");
              var el3 = dom.createTextNode("Código de compra");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("dd");
              var el3 = dom.createElement("strong");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("dt");
              var el3 = dom.createTextNode("Pago en");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("dd");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createTextNode("\n                Se ha efectuado el pago, puedes descargar tus boletos\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("table");
              dom.setAttribute(el1, "class", "table table-striped");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("thead");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Nombre");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Asiento");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Salida");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Destino");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("# de boleto");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Descargar boleto");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("tbody");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element4, [3, 0]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element4, [7]), 1, 1);
              morphs[2] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              morphs[3] = dom.createMorphAt(dom.childAt(fragment, [9, 3]), 1, 1);
              return morphs;
            },
            statements: [["content", "finishedSale.shortId", ["loc", [null, [220, 28], [220, 52]]]], ["block", "if", [["subexpr", "eq", [["get", "finishedSale.paymentProvider", ["loc", [null, [223, 28], [223, 56]]]], "BOOTH"], [], ["loc", [null, [223, 24], [223, 65]]]]], [], 0, 1, ["loc", [null, [223, 18], [227, 25]]]], ["block", "if", [["get", "finishedSale.bill", ["loc", [null, [234, 20], [234, 37]]]]], [], 2, null, ["loc", [null, [234, 14], [236, 21]]]], ["block", "each", [["get", "finishedSale.tickets", ["loc", [null, [249, 24], [249, 44]]]]], [], 3, null, ["loc", [null, [249, 16], [258, 25]]]]],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 261,
                  "column": 12
                },
                "end": {
                  "line": 264,
                  "column": 12
                }
              },
              "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h1");
              var el2 = dom.createTextNode("Favor de revisar el pago");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createTextNode("El pago no ha sido procesado, favor de revisar");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 215,
                "column": 10
              },
              "end": {
                "line": 265,
                "column": 10
              }
            },
            "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "finishedSale.payed", ["loc", [null, [216, 18], [216, 36]]]]], [], 0, 1, ["loc", [null, [216, 12], [264, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 8
            },
            "end": {
              "line": 266,
              "column": 8
            }
          },
          "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "currentStep", ["loc", [null, [36, 20], [36, 31]]]], "departure"], [], ["loc", [null, [36, 16], [36, 44]]]]], [], 0, null, ["loc", [null, [36, 10], [63, 17]]]], ["block", "if", [["subexpr", "eq", [["get", "currentStep", ["loc", [null, [64, 20], [64, 31]]]], "confirm"], [], ["loc", [null, [64, 16], [64, 42]]]]], [], 1, null, ["loc", [null, [64, 10], [163, 17]]]], ["block", "if", [["subexpr", "eq", [["get", "currentStep", ["loc", [null, [164, 20], [164, 31]]]], "payment"], [], ["loc", [null, [164, 16], [164, 42]]]]], [], 2, null, ["loc", [null, [164, 10], [183, 17]]]], ["block", "if", [["subexpr", "eq", [["get", "currentStep", ["loc", [null, [184, 20], [184, 31]]]], "reserved"], [], ["loc", [null, [184, 16], [184, 43]]]]], [], 3, null, ["loc", [null, [184, 10], [188, 17]]]], ["block", "if", [["subexpr", "eq", [["get", "currentStep", ["loc", [null, [189, 20], [189, 31]]]], "discountPassword"], [], ["loc", [null, [189, 16], [189, 51]]]]], [], 4, null, ["loc", [null, [189, 10], [214, 17]]]], ["block", "if", [["subexpr", "eq", [["get", "currentStep", ["loc", [null, [215, 20], [215, 31]]]], "payed"], [], ["loc", [null, [215, 16], [215, 40]]]]], [], 5, null, ["loc", [null, [215, 10], [265, 17]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 266,
              "column": 8
            },
            "end": {
              "line": 268,
              "column": 8
            }
          },
          "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "href", "");
          dom.setAttribute(el1, "class", "btn btn-info");
          var el2 = dom.createTextNode("Iniciar turno");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["startShift"], [], ["loc", [null, [267, 18], [267, 41]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 273,
            "column": 0
          }
        },
        "moduleName": "ticket-sales-frontend/templates/ticket-dashboard.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "dashboard-body");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("nav");
        dom.setAttribute(el2, "class", "navbar navbar-inverse navbar-fixed-top");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-fluid");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "navbar-header");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5, "type", "button");
        dom.setAttribute(el5, "class", "navbar-toggle collapsed");
        dom.setAttribute(el5, "data-toggle", "collapse");
        dom.setAttribute(el5, "data-target", "#navbar");
        dom.setAttribute(el5, "aria-expanded", "false");
        dom.setAttribute(el5, "aria-controls", "navbar");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "sr-only");
        var el7 = dom.createTextNode("Toggle navigation");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "icon-bar");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "icon-bar");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "icon-bar");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "class", "navbar-brand");
        dom.setAttribute(el5, "href", "#");
        var el6 = dom.createTextNode("\n        Tickets Medrano\n        /\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        /\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "id", "navbar");
        dom.setAttribute(el4, "class", "navbar-collapse collapse");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5, "class", "nav navbar-nav navbar-right");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createElement("a");
        var el8 = dom.createTextNode("Dashboard");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "");
        var el8 = dom.createTextNode("Salida");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-fluid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-sm-3 col-md-2 sidebar");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        dom.setAttribute(el5, "class", "page-header");
        var el6 = dom.createTextNode("Venta");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element30 = dom.childAt(fragment, [0]);
        var element31 = dom.childAt(element30, [1, 1]);
        var element32 = dom.childAt(element31, [1, 3]);
        var element33 = dom.childAt(element31, [3, 1, 3, 0]);
        var element34 = dom.childAt(element30, [3, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element32, 1, 1);
        morphs[1] = dom.createMorphAt(element32, 3, 3);
        morphs[2] = dom.createElementMorph(element33);
        morphs[3] = dom.createMorphAt(dom.childAt(element34, [1]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element34, [3]), 3, 3);
        return morphs;
      },
      statements: [["content", "model.terminal.officeName", ["loc", [null, [14, 8], [14, 37]]]], ["content", "model.terminal.terminalName", ["loc", [null, [16, 8], [16, 39]]]], ["element", "action", ["logout"], [], ["loc", [null, [22, 22], [22, 41]]]], ["content", "admin-menu", ["loc", [null, [31, 8], [31, 22]]]], ["block", "if", [["get", "model.boothStatus", ["loc", [null, [35, 14], [35, 31]]]]], [], 0, 1, ["loc", [null, [35, 8], [268, 15]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});