define('ticket-sales-frontend/controllers/admin-weekly-payments', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    types: ['Anticipo', 'Seguro'],
    paymentData: {},
    actions: {
      uploadFinished: function uploadFinished(data) {
        this.set('uploadedData', data.payments);
        this.set('invalidPayments', data.invalidUsers);
      }
    }
  });
});