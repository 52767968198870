define('ticket-sales-frontend/routes/search-results', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
	exports['default'] = _ember['default'].Route.extend({
		queryParams: {
			departureDate: {
				refreshModel: true
			},
			returningDate: {
				refreshModel: true
			},
			origin: {
				refreshModel: true
			},
			destination: {
				refreshModel: true
			},
			roundTrip: {
				refreshModel: true
			},
			adultCount: {
				refreshModel: true
			},
			childrenCount: {
				refreshModel: true
			},
			infantCount: {
				refreshModel: true
			},
			olderAdultCount: {
				refreshModel: true
			}
		},
		model: function model(params) {
			if (params.departureDate) {
				params.departureDate = new Date(params.departureDate);
			}
			if (params.returningDate) {
				params.returningDate = new Date(params.returningDate);
			}
			if (!params.olderAdultCount) {
				params.olderAdultCount = 0;
			}
			if (!params.infantCount) {
				params.infantCount = 0;
			}
			if (!params.childrenCount) {
				params.childrenCount = 0;
			}
			if (!params.adultCount) {
				params.adultCount = 0;
			}
			params.timeZone = 'America/Mexico_City';
			return _emberData['default'].PromiseObject.create({
				promise: jQuery.ajax({
					url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/trip',
					data: JSON.stringify(params),
					contentType: 'application/json; charset=utf-8',
					method: 'POST'
				}).then(function (data) {
					var currentTime = moment();
					var startTime = moment(params.departureDate).startOf('day');
					var endTime = moment(params.departureDate).endOf('day');
					var returnStartTime = moment(params.returningDate).startOf('day');
					var returnEndTime = moment(params.returningDate).endOf('day');
					data.departureQuotes = data.departureQuotes.filter(function (current) {
						var currentDate = moment(current.departingDate);
						return currentTime.isSameOrBefore(currentDate) && startTime.isSameOrBefore(currentDate) && currentDate.isSameOrBefore(endTime) && !['1060SEC', '6010SEC'].contains(current.route.name);
					});
					data.departureQuotes.sort(function (a, b) {
						return new Date(a.departingDate) - new Date(b.departingDate);
					});
					data.returnQuotes = data.returnQuotes.filter(function (current) {
						var currentDate = moment(current.departingDate);
						return returnStartTime.isSameOrBefore(currentDate) && currentDate.isSameOrBefore(returnEndTime);
					});
					data.returnQuotes.sort(function (a, b) {
						return new Date(a.departingDate) - new Date(b.departingDate);
					});
					return data;
				})
			});
		}
	});
});