define('ticket-sales-frontend/controllers/ticket-baggage', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    step: 'search',
    sale: {
      totalPrice: 0
    },
    baggage: {},
    getTripData: function getTripData(quote) {
      var tripData = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/tripData';
      return _emberData['default'].PromiseObject.create({
        promise: jQuery.get(tripData, {
          serviceLevelId: quote.serviceLevelId,
          routeId: quote.route.id,
          originId: quote.origin.id,
          destinationId: quote.destination.id,
          tripDate: quote.routeDepartingDate,
          reverse: quote.reverse
        })
      });
    },
    actions: {
      searchTrip: function searchTrip(params) {
        var _this = this;

        var searchButton = params.searchButton;
        params.olderAdultCount = 0;
        params.infantCount = 0;
        params.childrenCount = 0;
        params.adultCount = 0;
        params.timeZone = 'America/Mexico_City';
        delete params.searchButton;
        jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/trip',
          data: JSON.stringify(params),
          contentType: 'application/json; charset=utf-8',
          method: 'POST'
        }).then(function (data) {
          var startTime = moment(params.departureDate).startOf('day');
          var endTime = moment(params.departureDate).endOf('day');
          _this.set('searchDepartures', data.departureQuotes.filter(function (current) {
            var currentDate = moment(current.departingDate);
            return startTime.isSameOrBefore(currentDate) && currentDate.isSameOrBefore(endTime);
          }));
          searchButton.stop();
        }, function () {
          searchButton.stop();
        });
      },
      selectQuote: function selectQuote(quote) {
        var trip = this.getTripData(quote);
        this.set('sale.departureTrip', trip);
        this.set('sale.departureQuote', quote);
        this.set('step', 'sell');
        this.set('quote', quote);
      },
      payment: function payment() {
        this.set('step', 'payment');
      },
      paymentReceived: function paymentReceived(paymentData) {
        var _this2 = this;

        paymentData.email = 'dev.tickets.medrano@gmail.com';
        paymentData.terminalId = this.get('model.terminal.id');
        paymentData.baggage = this.get('baggage');
        paymentData.baggage.totalPrice = parseInt(this.get('sale.totalPrice'), 10);
        jQuery('.loading').show();
        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/payBooth', {
          method: 'POST',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify(paymentData)
        }).then(function (data) {
          jQuery.ajax({
            url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/saleData/' + data.shortId
          }).then(function (saleData) {
            jQuery('.loading').hide();
            location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/downloadBaggageTicket/' + saleData.shortId + '?timeZone=' + 'America/Mexico_City';
            _this2.set('step', 'payed');
            _this2.set('finishedSale', saleData);
          });
        });
      }
    }
  });
});