define('ticket-sales-frontend/controllers/sale-points', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    init: function init() {
      var _this = this;

      this._super();
      _ember['default'].run.schedule('afterRender', this, function () {
        var infowindows = [];
        var mapCanvas = jQuery('.map-canvas').get(0);
        var map = new google.maps.Map(mapCanvas, {
          center: { lat: -34.397, lng: 150.644 },
          zoom: 8
        });
        var bounds = new google.maps.LatLngBounds();
        _this.get('model').forEach(function (office) {
          if (office.latitude && office.longitude) {
            var infowindow = new google.maps.InfoWindow({
              content: office.name
            });
            infowindows.push(infowindow);
            var marker = new google.maps.Marker({
              position: new google.maps.LatLng(office.latitude, office.longitude),
              map: map
            });
            bounds.extend(marker.position);
            marker.addListener('click', function () {
              infowindows.forEach(function (i) {
                return i.close();
              });
              infowindow.open(map, marker);
            });
          }
        });
        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds);
      });
    }
  });
});