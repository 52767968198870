define('ticket-sales-frontend/components/user-login', ['exports', 'ember', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _ticketSalesFrontendConfigEnvironment) {
	exports['default'] = _ember['default'].Component.extend({
		session: _ember['default'].inject.service('session'),
		authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
		register: _ember['default'].Object.create({
			username: '',
			email: ''
		}),
		init: function init() {
			var _this = this;

			this._super();

			this.get('register').addObserver('username', this, this.checkUnique);
			this.get('register').addObserver('email', this, this.checkUnique);

			window.googleLogin = function (googleUser) {
				var id_token = googleUser.getAuthResponse().id_token;
				_this.send('googleLogin', id_token);
			};
		},
		checkUnique: function checkUnique() {
			var _this2 = this;

			var register = this.get('register');
			jQuery.ajax({
				url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/register/uniques',
				data: JSON.stringify(register),
				contentType: 'application/json; charset=utf-8',
				method: 'POST',
				success: function success(data) {
					_this2.set('usernameRepeatedError', data.username);
					_this2.set('emailRepeatedError', data.email);
					_this2.set('repeatedError', data.username || data.email);
				}
			});
		},
		isPopupShowing: function isPopupShowing() {
			return jQuery('#soap-popupbox').length > 0 && jQuery('#soap-popupbox').css('display') === 'block';
		},
		continueRegistration: function continueRegistration(socialData) {
			if (!this.isPopupShowing()) {
				return false;
			}
			this.get('register').setProperties(socialData);
			jQuery('.goto-signup-form').click();
		},
		continueSocialLogin: function continueSocialLogin(provider, token) {
			var _this3 = this;

			var socialDataURL = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/social/validate';
			jQuery.get(socialDataURL, {
				tokenId: token,
				provider: provider
			}).done(function (data) {
				if (!data.username) {
					_this3.continueRegistration(data);
				} else {
					_this3.get('session').authenticate('authenticator:social', data)['catch'](function () {
						jQuery('.opacity-overlay').fadeOut();
					});
				}
			});
		},
		isRegisterValid: function isRegisterValid() {
			var register = this.get('register');
			return register && register.password && register.username && register.name && register.lastName && register.confirmPassword;
		},
		actions: {
			authenticate: function authenticate() {
				var _this4 = this;

				var _getProperties = this.getProperties('identification', 'password');

				var identification = _getProperties.identification;
				var password = _getProperties.password;

				console.log('MUERETE');
				this.get('session').authenticate('authenticator:oauth2', identification, password).then(function () {
					_this4.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/currentProfile').then(function (profile) {
						_this4.get('session').set('data.profile', profile);
						console.log(profile.permissions);
						if (profile.permissions.indexOf('configure-id') >= 0) {
							_this4.container.lookup('controller:application').transitionToRoute('admin-dashboard');
						} else if (profile.permissions.indexOf('weekly-payment-id') >= 0) {
							_this4.container.lookup('controller:application').transitionToRoute('admin-weekly-payments');
						} else if (profile.permissions.indexOf('payments-id') >= 0) {
							_this4.container.lookup('controller:application').transitionToRoute('admin-payments');
						} else {
							_this4.container.lookup('controller:application').transitionToRoute('ticket-dashboard');
						}
					});
				})['catch'](function (reason) {
					console.log('ERROR', reason);
				});
			},
			facebookLogin: function facebookLogin() {
				var _this5 = this;

				FB.getLoginStatus(function (response) {
					if (response.status === 'connected') {
						_this5.continueSocialLogin('facebook', response.authResponse.accessToken);
					} else {
						FB.login(function (response) {
							_this5.continueSocialLogin('facebook', response.authResponse.accessToken);
						}, { scope: 'email,public_profile' });
					}
				});
			},
			googleLogin: function googleLogin(tokenId) {
				this.continueSocialLogin('google', tokenId);
			},
			registerUser: function registerUser() {
				var register = this.get('register');
				var session = this.get('session');
				if (this.isRegisterValid() && !this.get('repeatedError')) {
					if (register.password !== register.confirmPassword) {
						this.set('registerPasswordErrors', true);
						return;
					}
					this.set('registerPasswordErrors', false);
					this.set('registerErrors', false);

					jQuery.ajax({
						url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/register',
						data: JSON.stringify(register),
						contentType: 'application/json; charset=utf-8',
						method: 'POST',
						success: function success(data) {
							session.authenticate('authenticator:social', data).then(function () {
								jQuery('.opacity-overlay').fadeOut();
							});
						}
					});
				} else {
					this.set('registerErrors', true);
				}
			}
		}
	});
});