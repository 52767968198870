define('ticket-sales-frontend/controllers/joined-payments', ['exports', 'ember', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    report: {},
    selectedTrips: [],
    actions: {
      selectTrip: function selectTrip(trip) {
        this.get('selectedTrips').addObject(trip);
      },
      joinTrips: function joinTrips(selectedTrips) {
        var _this = this;

        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/joinPayments', {
          method: 'POST',
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify({
            selectedTrips: selectedTrips
          })
        }).then(function () {
          _this.set('selectedTrips', []);
          _this.set('trips', []);
        });
      },
      searchPayments: function searchPayments(report) {
        var _this2 = this;

        localforage.getItem('terminalData').then(function (data) {
          _this2.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/payment/unjoined/' + data.id + '?startDate=' + report.from.toISOString() + '&endDate=' + report.to.toISOString()).then(function (payments) {
            _this2.set('trips', payments);
          });
        });
      }
    }
  });
});