define('ticket-sales-frontend/app', ['exports', 'ember', 'ember-resolver', 'ember/load-initializers', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberResolver, _emberLoadInitializers, _ticketSalesFrontendConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  _ember['default'].TextField.reopen({
    attributeBindings: ['data-minlength']
  });

  $.fn.validator.Constructor.DEFAULTS.errors.minlength = 'Dato muy corto';
  $.fn.validator.Constructor.DEFAULTS.errors.match = 'Dato inválido';

  App = _ember['default'].Application.extend({
    modulePrefix: _ticketSalesFrontendConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _ticketSalesFrontendConfigEnvironment['default'].podModulePrefix,
    Resolver: _emberResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _ticketSalesFrontendConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});