define('ticket-sales-frontend/controllers/location-map', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    map: null,
    bounds: null,
    init: function init() {
      _ember['default'].run.later(this, function () {
        var mapCanvas = jQuery('.map-canvas').get(0);
        this.map = new google.maps.Map(mapCanvas, {
          zoom: 8
        });
        this.bounds = new google.maps.LatLngBounds();
        this.loadData();
      }, 1000);
    },
    loadData: function loadData() {
      var _this = this;

      var infowindows = [];
      var locations = this.get('model');
      var positions = locations.map(function (location) {
        var infowindow = new google.maps.InfoWindow({
          content: location.bus.busNumber + ' ' + location.location
        });
        infowindows.push(infowindow);
        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(location.latitude, location.longitude),
          map: _this.map
        });
        _this.bounds.extend(marker.position);
        marker.addListener('click', function () {
          infowindows.forEach(function (i) {
            return i.close();
          });
          infowindow.open(this.map, marker);
        });
        return marker.position;
      });
      this.map.setCenter(this.bounds.getCenter());
      this.map.fitBounds(this.bounds);
      console.log(locations);
      // Ember.run.later(this, 'loadData', 30000);
    }
  });
});