define('ticket-sales-frontend/components/ticket-trip-map', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		init: function init() {
			this._super();
			var icons = ['blue-dot.png', 'brown-dot.png', 'green-dot.png', 'grey-dot.png', 'blue-dot2.png', 'light-green-dot.png', 'orange-dot.png', 'weird-blue3-dot.png', 'orange-yellow-dot.png', 'pink-dot.png', 'poo-dot.png', 'purple-dot.png', 'lemon-dot.png', 'red-dot.png', 'strong-green-dot.png', 'violet-dot.png', 'yellow-dot.png', 'white-dot.png', 'mexican-pink-dot.png', 'weird-blue-dot.png'];
			var iconIdx = 0;
			this.get('trip').then(function (trip) {
				trip.stops.forEach(function (stop) {
					_ember['default'].set(stop, 'icon', '/images/maps/' + icons[iconIdx++]);
					if (iconIdx >= icons.length) {
						iconIdx = 0;
					}
				});
			});
		},
		didInsertElement: function didInsertElement() {
			var ctx = this.$();
			var mapCanvas = jQuery('.map-canvas', ctx).get(0);
			var map = new google.maps.Map(mapCanvas, {
				zoom: 8
			});
			var bounds = new google.maps.LatLngBounds();
			this.get('trip').then(function (trip) {
				var positions = trip.stops.map(function (stop) {
					var marker = new google.maps.Marker({
						position: new google.maps.LatLng(stop.location.lat, stop.location.lon),
						icon: stop.icon,
						map: map
					});
					bounds.extend(marker.position);
					return marker.position;
				});
				var polyline = new google.maps.Polyline({
					path: positions,
					geodesic: true,
					strokeColor: '#FF0000',
					strokeOpacity: 1.0,
					strokeWeight: 2
				});
				map.setCenter(bounds.getCenter());
				map.fitBounds(bounds);
				polyline.setMap(map);
				window.scrollTo(0, 0);
			});
		}
	});
});