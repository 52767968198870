define('ticket-sales-frontend/components/file-upload', ['exports', 'ember-uploader', 'ticket-sales-frontend/config/environment'], function (exports, _emberUploader, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _emberUploader['default'].FileField.extend({
    filesDidChange: function filesDidChange(files) {
      var _this = this;

      var uploader = _emberUploader['default'].Uploader.create({
        url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + this.get('url')
      });
      if (!Ember.isEmpty(files)) {
        uploader.upload(files[0], this.get('extra'));
      }

      uploader.on('didUpload', function (e) {
        _this.sendAction('uploadFinished', e);
      });
    }
  });
});