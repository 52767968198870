define('ticket-sales-frontend/controllers/admin-dashboard', ['exports', 'ember', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    session: _ember['default'].inject.service('session'),
    selectedTerminal: null,
    oldTerminal: {
      currentAmount: 0,
      currentPayedAmount: 0,
      paymentAvailableAmount: 0
    },
    newTerminal: {
      currentAmount: 0,
      currentPayedAmount: 0,
      paymentAvailableAmount: 0,
      salesTerminal: false,
      paymentTerminal: false
    },
    terminals: [],
    profile: (function () {
      return this.get('session.data.profile');
    }).property(),
    locationObserver: _ember['default'].observer('oldTerminal.officeLocation', function () {
      var _this = this;

      var officeLocation = this.get('oldTerminal.officeLocation');
      officeLocation = officeLocation.substring(officeLocation.lastIndexOf('/') + 1);
      this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/office/' + officeLocation).then(function (data) {
        data.sort(function (a, b) {
          return a.terminalName.localeCompare(b.terminalName);
        });
        _this.set('terminals', data);
      });
    }),
    lookupBeginnings: function lookupBeginnings(text) {
      var beginningSuggestionsUrl = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/beginning';
      return jQuery.get(beginningSuggestionsUrl, { s: text });
    },
    uuid: function uuid() {
      var buf = new Uint32Array(4);
      window.crypto.getRandomValues(buf);
      var idx = -1;
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        idx++;
        var r = buf[idx >> 3] >> idx % 8 * 4 & 15;
        var v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
    },
    actions: {
      createTerminal: function createTerminal() {
        var _this2 = this;

        var newTerminal = JSON.parse(JSON.stringify(this.get('newTerminal')));
        newTerminal.stopOffName = newTerminal.stopOffName.value;
        newTerminal.terminalId = this.uuid();
        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/salesTerminals', {
          method: 'POST',
          data: JSON.stringify(newTerminal),
          contentType: 'application/json; charset=utf-8'
        }).then(function () {
          localforage.setItem('terminalData', {
            id: newTerminal.terminalId
          }, function () {
            _this2.set('changeTerminalConfiguration', false);
            location.reload();
          });
        });
      },
      selectTerminal: function selectTerminal() {
        var _this3 = this;

        if (this.get('selectedTerminal')) {
          localforage.setItem('terminalData', {
            id: this.get('selectedTerminal')
          }, function () {
            _this3.set('changeTerminalConfiguration', false);
            location.reload();
          });
        }
      },
      enableTerminalConfiguration: function enableTerminalConfiguration() {
        this.set('changeTerminalConfiguration', true);
      }
    }
  });
});