define('ticket-sales-frontend/routes/stop-checker', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
	exports['default'] = _ember['default'].Route.extend({
		model: function model(params) {
			return _emberData['default'].PromiseObject.create({
				promise: jQuery.ajax({
					url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/stopOffs',
					contentType: 'application/json; charset=utf-8'
				})
			});
		}
	});
});