define('ticket-sales-frontend/authenticators/social', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'ember'], function (exports, _emberSimpleAuthAuthenticatorsOauth2PasswordGrant, _ember) {
	var RSVP = _ember['default'].RSVP;
	var isEmpty = _ember['default'].isEmpty;
	exports['default'] = _emberSimpleAuthAuthenticatorsOauth2PasswordGrant['default'].extend({
		serverTokenEndpoint: 'http://api.sales.medrano.iamedu.io/oauth/token',
		clientId: 'clientapp',
		clientSecret: '123456',
		makeRequest: function makeRequest(url, data) {
			var options = {
				url: url,
				data: data,
				type: 'POST',
				dataType: 'json',
				contentType: 'application/x-www-form-urlencoded'

			};
			var clientId = this.get('clientId');
			var clientSecret = this.get('clientSecret');

			if (!isEmpty(clientId)) {
				var base64ClientId = window.btoa(clientId.concat(':').concat(clientSecret));
				_ember['default'].merge(options, {
					headers: {
						Authorization: 'Basic ' + base64ClientId
					}
				});
			}

			return _ember['default'].$.ajax(options);
		},
		authenticate: function authenticate(token) {
			return RSVP.resolve(token);
		}
	});
});