define('ticket-sales-frontend/controllers/admin-trip-search', ['exports', 'ember', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    selectedBeginning: {
      name: null
    },
    search: {
      driverName: ''
    },
    actions: {
      showTrip: function showTrip(trip) {
        this.set('trip', trip);
        this.set('trips', null);
      },
      searchData: function searchData(stopOff, driverName, date) {
        var _this = this;

        jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/findTrips', {
          stopOff: stopOff,
          driverName: driverName,
          date: date.toISOString()
        }).then(function (data) {
          data.forEach(function (trip) {
            var start;
            var end;
            if (trip.tripData.reverse) {
              start = trip.tripData.stops[0].name;
              end = trip.tripData.stops[trip.tripData.stops.length - 1].name;
            } else {
              start = trip.tripData.stops[trip.tripData.stops.length - 1].name;
              end = trip.tripData.stops[0].name;
            }
            trip.startingStop = start;
            trip.endingStop = end;
          });
          _this.set('trips', data);
        });
      }
    }
  });
});