define('ticket-sales-frontend/controllers/pay-reserved', ['exports', 'ember', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    step: 'search',
    actions: {
      cancelReservation: function cancelReservation(tripData) {
        var _this = this;

        jQuery('.loading').show();
        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/cancelReservation/' + this.get('saleData.id')).then(function (data) {
          console.log(data);
          jQuery('.loading').hide();
          _this.set('currentIds', []);
          _this.set('quotes', []);
          _this.set('sale', {});
          _this.set('saleData', {});
          _this.set('passengers', []);
          _this.set('tripData', {});
          _this.set('step', 'search');
        });
      },
      paymentReceived: function paymentReceived(paymentData) {
        var _this2 = this;

        paymentData.terminalId = this.get('model.terminal.id');
        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/payReservation/' + this.get('saleData.id'), {
          method: 'POST',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify(paymentData)
        }).then(function (data) {
          jQuery.ajax({
            url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/saleData/' + data.shortId
          }).then(function (saleData) {
            location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/downloadTickets/' + saleData.id + '.pdf?timeZone=' + 'America/Mexico_City';
            _this2.set('currentIds', []);
            _this2.set('quotes', []);
            _this2.set('sale', {});
            _this2.set('saleData', {});
            _this2.set('passengers', []);
            _this2.set('tripData', {});
            _this2.set('step', 'search');
          });
        });
      },
      returnToSearch: function returnToSearch() {
        this.set('step', 'search');
      },
      lookup: function lookup(confirmationCode) {
        var _this3 = this;

        jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/saleData/' + confirmationCode
        }).then(function (saleData) {
          if (saleData.payed) {
            return;
          }
          jQuery.ajax({
            url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/' + saleData.tripId
          }).then(function (tripData) {
            var sale = {
              totalPrice: saleData.tickets.reduce(function (acc, ticket) {
                return acc + ticket.soldPrice;
              }, 0)
            };
            _this3.set('sale', sale);
            _this3.set('step', 'payment');
            _this3.set('saleData', saleData);
            _this3.set('passengers', saleData.tickets);
            _this3.set('tripData', tripData);
            console.log(saleData, tripData);
          });
        });
      }
    }
  });
});