define('ticket-sales-frontend/components/ticket-client-payment', ['exports', 'ember', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    paymentType: 'creditCard',
    formSelectors: {
      numberInput: 'input[name="number"]',
      expiryInput: 'input[name="expiry"]',
      cvcInput: 'input[name="cvc"]',
      nameInput: 'input[name="first-name"], input[name="last-name"]'
    },
    billingSettlements: [],
    settlements: [],
    coupon: '',
    couponAmount: 0,
    showCoupon: false,
    showPayment: false,
    creditCard: {},
    address: {},
    billingAddress: {},
    billingData: {
      sameAddress: true
    },
    store: {
      place: 'OXXO',
      name: '',
      lastNames: '',
      email: ''
    },
    general: {
      needBill: false
    },
    stores: [{
      id: 'OXXO',
      name: 'Oxxo'
    }, {
      id: 'CASA_LEY',
      name: 'Casa Ley'
    }, {
      id: 'SEVEN_ELEVEN',
      name: 'Seven Eleven'
    }, {
      id: 'EXTRA',
      name: 'Extra'
    }, {
      id: 'ELEKTRA',
      name: 'Elektra'
    }, {
      id: 'COPPEL',
      name: 'Coppel'
    }, {
      id: 'PITICO',
      name: 'Pitico'
    }, {
      id: 'TELECOMM',
      name: 'Telecomm'
    }, {
      id: 'FARMACIA_ABC',
      name: 'Farmacia ABC'
    }, {
      id: 'FARMACIA_ESQUIVAR',
      name: 'Farmacia Esquivar'
    }, {
      id: 'DEL_SOL',
      name: 'Del Sol'
    }, {
      id: 'WOOLWORTH',
      name: 'Woolworth'
    }],
    buildCreditCardData: function buildCreditCardData(general, billingAddress, billingData, card, address, sale, passengers) {
      if (billingData.sameAddress) {
        billingAddress = {
          postalCode: address.postalCode,
          address: address.address,
          municipality: address.municipality,
          reference: address.reference,
          settlement: address.settlement,
          state: address.state
        };
      }
      var baseData = this.buildBaseData(general, billingAddress, billingData, sale, passengers);
      var expirationMonth = card.expiration.split("/")[0];
      var expirationYear = card.expiration.split("/")[1];
      card.expirationMonth = parseInt(expirationMonth, 10);
      card.expirationYear = parseInt(expirationYear, 10);
      baseData.creditCard = card;
      baseData.address = address;
      baseData.paymentParts = [{
        amount: this.get('missingPayment'),
        change: 0,
        paymentType: 'INTERNET_CREDIT_CARD'
      }];
      return baseData;
    },
    buildStoreData: function buildStoreData(general, billingAddress, billingData, store, sale, passengers) {
      var baseData = this.buildBaseData(general, billingAddress, billingData, sale, passengers);
      baseData.store = store;
      baseData.paymentParts = [{
        amount: this.get('missingPayment'),
        change: 0,
        paymentType: 'INTERNET_STORE'
      }];
      return baseData;
    },
    buildBaseData: function buildBaseData(general, billingAddress, billingData, sale, passengers) {
      return {
        coupon: this.get('couponData.name'),
        couponAmount: this.get('couponPaymentData.amount'),
        email: general.email,
        passengers: passengers,
        timeZone: 'America/Mexico_City',
        departureTrip: {
          id: sale.departureTrip.get('id')
        },
        needBill: general.needBill,
        billingAddress: billingAddress,
        billingData: billingData,
        returnTrip: {
          id: sale.returnTrip && sale.returnTrip.get('id')
        },
        departureSegment: {
          startingStop: {
            id: sale.departureQuote.origin.id
          },
          endingStop: {
            id: sale.departureQuote.destination.id
          }
        },
        returnSegment: {
          startingStop: {
            id: sale.returnQuote && sale.returnQuote.origin.id
          },
          endingStop: {
            id: sale.returnQuote && sale.returnQuote.destination.id
          }
        }
      };
    },
    actions: {
      postalCodeEntry: function postalCodeEntry(data) {
        var _this = this;

        if (data.length >= 5) {
          jQuery('.loading').show();
          jQuery.ajax({
            url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/address/' + data,
            success: function success(data) {
              if (data) {
                if (data.municipality) {
                  _this.set('address.municipality', data.municipality);
                }
                if (data.state) {
                  _this.set('address.state', data.state);
                }
                if (data.settlements) {
                  _this.set('settlements', data.settlements);
                }
              }
              jQuery('.loading').hide();
            }
          });
        }
      },
      billingPostalCodeEntry: function billingPostalCodeEntry(data) {
        var _this2 = this;

        if (data.length >= 5) {
          jQuery('.loading').show();
          jQuery.ajax({
            url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/address/' + data,
            success: function success(data) {
              if (data) {
                if (data.municipality) {
                  _this2.set('billingAddress.municipality', data.municipality);
                }
                if (data.state) {
                  _this2.set('billingAddress.state', data.state);
                }
                if (data.settlements) {
                  _this2.set('billingSettlements', data.settlements);
                }
              }
              jQuery('.loading').hide();
            }
          });
        }
      },
      couponContinue: function couponContinue(coupon, couponAmount) {
        var totalPrice = this.get('sale.totalPrice');
        var couponAvailable = this.get('couponData.amount');

        if (couponAmount > couponAvailable) {
          this.set('couponError', 'El monto elegido es mayor al monto disponible en monedero');
          return;
        }

        var missingPayment = totalPrice - couponAmount;

        this.set('couponError', null);
        this.set('couponPaymentData', {
          coupon: coupon,
          amount: couponAmount
        });
        if (couponAmount < totalPrice) {
          this.set('missingPayment', missingPayment);
          this.set('showPayment', true);
        }
      },
      cancelCoupon: function cancelCoupon() {
        this.set('showCoupon', false);
      },
      lookupCoupon: function lookupCoupon(coupon) {
        var _this3 = this;

        jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/couponData/number/' + coupon,
          success: function success(data) {
            if (data) {
              _this3.set('couponData', data);
              _this3.set('couponError', false);
            } else {
              _this3.set('couponError', 'El monedero es inválido');
            }
          },
          error: function error(data) {
            _this3.set('couponError', 'El monedero es inválido');
          }
        });
      },
      selectPaymentType: function selectPaymentType(paymentType) {
        this.set('billingData.sameAddress', paymentType === 'creditCard');
        this.set('paymentType', paymentType);
      },
      submitVisaPayment: function submitVisaPayment() {
        var general = this.get('general');
        var billingAddress = this.get('billingAddress');
        var billingData = this.get('billingData');
        var saleData = this.get('sale');
        var passengers = this.get('passengers');
        jQuery('.loading').show();
        jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/payWithMastercard',
          type: 'POST',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify(this.buildBaseData(general, billingAddress, billingData, saleData, passengers))
        }).then(function (data) {
          console.log(data);
          jQuery('.loading').hide();
          Checkout.configure({
            merchant: '1079224',
            order: {
              amount: function amount() {
                return saleData.totalPrice;
              },
              currency: 'MXN',
              description: 'Tickets turismo en omnibus',
              id: data.id
            },
            session: {
              id: data.payment.sessionId
            },
            interaction: {
              merchant: {
                name: 'Turismo en omnibus',
                address: {
                  line1: 'Rosalio Bustamante 1004',
                  line2: '89160 Tampico'
                }
              }
            }
          });
          Checkout.showPaymentPage();
        });
      },
      submitPaypalPayment: function submitPaypalPayment() {
        var general = this.get('general');
        var billingAddress = this.get('billingAddress');
        var billingData = this.get('billingData');
        var saleData = this.get('sale');
        var passengers = this.get('passengers');
        jQuery('.loading').show();
        jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/payWithPaypal',
          type: 'POST',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify(this.buildBaseData(general, billingAddress, billingData, saleData, passengers))
        }).then(function (data) {
          jQuery('.loading').hide();
          location.href = data.payment.approvalUrl;
        });
      },
      submitCreditCardPayment: function submitCreditCardPayment() {
        var _this4 = this;

        var general = this.get('general');
        var billingAddress = this.get('billingAddress');
        var billingData = this.get('billingData');
        var cardData = this.get('creditCard');
        var saleData = this.get('sale');
        var passengers = this.get('passengers');
        var address = this.get('address');
        jQuery('#card-form').validator('validate');
        if (!jQuery('#card-form').data('bs.validator').hasErrors()) {
          console.log('Pasajeros', passengers);
          jQuery('.loading').show();
          jQuery.ajax({
            url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/payWithCreditCard',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            data: JSON.stringify(this.buildCreditCardData(general, billingAddress, billingData, cardData, address, saleData, passengers))
          }).then(function (data) {
            jQuery('.loading').hide();
            _this4.sendAction('finishedSale', data.shortId);
          });
        }
      },
      submitStorePayment: function submitStorePayment() {
        var _this5 = this;

        var storeData = this.get('store');
        var billingAddress = this.get('billingAddress');
        var billingData = this.get('billingData');
        var saleData = this.get('sale');
        var passengers = this.get('passengers');
        var general = {
          email: storeData.email,
          needBill: this.get('general.needBill')
        };
        jQuery('.loading').show();
        jQuery('#store-form').validator('validate');
        if (!jQuery('#store-form').data('bs.validator').hasErrors()) {
          jQuery.ajax({
            url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/payInStore',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            data: JSON.stringify(this.buildStoreData(general, billingAddress, billingData, storeData, saleData, passengers))
          }).then(function (data) {
            jQuery('.loading').hide();
            _this5.sendAction('pendingSale', data.shortId);
          });
        }
      },
      selectStores: function selectStores() {
        this.set('showPayment', true);
        this.set('paymentType', 'store');
        this.set('missingPayment', this.get('sale.totalPrice'));
      },
      selectVisa: function selectVisa() {
        this.set('showPayment', true);
        this.set('paymentType', 'visa');
        this.set('missingPayment', this.get('sale.totalPrice'));
      },
      selectWallet: function selectWallet() {
        this.set('showCoupon', true);
      },
      returnToConfirmation: function returnToConfirmation() {
        this.sendAction('returnPayment');
      },
      returnPayment: function returnPayment() {
        this.set('showCoupon', false);
        this.set('showPayment', false);
        window.scrollTo(0, 0);
      }
    },
    didRender: function didRender() {
      jQuery('#card-form').validator();
      jQuery('#store-form').validator();
    }
  });
});