define('ticket-sales-frontend/controllers/scan-ticket', ['exports', 'ember', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      sendThing: function sendThing() {
        var _this = this;

        var ticketNumber = this.get('ticketNumber');
        this.set('ticketNumber', '');
        jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/recordUsed/' + ticketNumber).then(function (ticket) {
          _this.set('currentTicket', ticket);
        });
      }
    }
  });
});