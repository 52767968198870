define('ticket-sales-frontend/controllers/ticket-dashboard-coupon', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    step: 'sale',
    tz: 'America/Mexico_City',
    enableBuyCard: (function () {
      return $.inArray('buy-electronic-card-id', this.get('session.data.profile.permissions')) >= 0;
    }).property(),
    actions: {
      paymentReceived: function paymentReceived(data) {
        var _this = this;

        var paymentData = {
          paymentParts: data.paymentParts,
          cardNumber: this.get('cardNumber'),
          name: this.get('name')
        };
        paymentData.terminalId = this.get('model.terminal.id');
        jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/payCoupon',
          type: 'POST',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify(paymentData)
        }).then(function (response) {
          _this.set('step', 'result');
          _this.set('response', response);
        });
      }
    }
  });
});