define("ticket-sales-frontend/templates/admin-loans", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 10
            },
            "end": {
              "line": 49,
              "column": 10
            }
          },
          "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1, "class", "table table-striped");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          var el5 = dom.createTextNode("Nombre");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1, 1, 1, 3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element5, 0, 0);
          morphs[1] = dom.createMorphAt(element5, 2, 2);
          return morphs;
        },
        statements: [["content", "account.employee.name", ["loc", [null, [45, 20], [45, 45]]]], ["content", "account.employee.lastName", ["loc", [null, [45, 46], [45, 75]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 68,
                  "column": 20
                },
                "end": {
                  "line": 70,
                  "column": 20
                }
              },
              "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      Porcentaje\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 70,
                  "column": 20
                },
                "end": {
                  "line": 72,
                  "column": 20
                }
              },
              "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      Monto\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 75,
                  "column": 20
                },
                "end": {
                  "line": 77,
                  "column": 20
                }
              },
              "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      Daño general a la unidad\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 77,
                    "column": 20
                  },
                  "end": {
                    "line": 79,
                    "column": 20
                  }
                },
                "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      Personal\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.2.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 79,
                      "column": 20
                    },
                    "end": {
                      "line": 81,
                      "column": 20
                    }
                  },
                  "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      Pasajero olvidado\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.2.0",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 81,
                        "column": 20
                      },
                      "end": {
                        "line": 83,
                        "column": 20
                      }
                    },
                    "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      Equipaje perdido\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.2.0",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 83,
                          "column": 20
                        },
                        "end": {
                          "line": 85,
                          "column": 20
                        }
                      },
                      "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                      Anticipo de sueldo\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.2.0",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 85,
                            "column": 20
                          },
                          "end": {
                            "line": 87,
                            "column": 20
                          }
                        },
                        "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                      Excedente de diesel\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() {
                        return [];
                      },
                      statements: [],
                      locals: [],
                      templates: []
                    };
                  })();
                  var child1 = (function () {
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.2.0",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 87,
                            "column": 20
                          },
                          "end": {
                            "line": 89,
                            "column": 20
                          }
                        },
                        "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                      Otro\n                    ");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() {
                        return [];
                      },
                      statements: [],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.2.0",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 85,
                          "column": 20
                        },
                        "end": {
                          "line": 89,
                          "column": 20
                        }
                      },
                      "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["block", "if", [["subexpr", "eq", [["get", "loan.concept", ["loc", [null, [85, 34], [85, 46]]]], "diesel-exceded"], [], ["loc", [null, [85, 30], [85, 64]]]]], [], 0, 1, ["loc", [null, [85, 20], [89, 20]]]]],
                    locals: [],
                    templates: [child0, child1]
                  };
                })();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.2.0",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 83,
                        "column": 20
                      },
                      "end": {
                        "line": 89,
                        "column": 20
                      }
                    },
                    "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["subexpr", "eq", [["get", "loan.concept", ["loc", [null, [83, 34], [83, 46]]]], "advance"], [], ["loc", [null, [83, 30], [83, 57]]]]], [], 0, 1, ["loc", [null, [83, 20], [89, 20]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.2.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 81,
                      "column": 20
                    },
                    "end": {
                      "line": 89,
                      "column": 20
                    }
                  },
                  "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "eq", [["get", "loan.concept", ["loc", [null, [81, 34], [81, 46]]]], "lost-suitcase"], [], ["loc", [null, [81, 30], [81, 63]]]]], [], 0, 1, ["loc", [null, [81, 20], [89, 20]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 79,
                    "column": 20
                  },
                  "end": {
                    "line": 89,
                    "column": 20
                  }
                },
                "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "eq", [["get", "loan.concept", ["loc", [null, [79, 34], [79, 46]]]], "forgotten-passenger"], [], ["loc", [null, [79, 30], [79, 69]]]]], [], 0, 1, ["loc", [null, [79, 20], [89, 20]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 77,
                  "column": 20
                },
                "end": {
                  "line": 89,
                  "column": 20
                }
              },
              "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "loan.concept", ["loc", [null, [77, 34], [77, 46]]]], "personal"], [], ["loc", [null, [77, 30], [77, 58]]]]], [], 0, 1, ["loc", [null, [77, 20], [89, 20]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 14
              },
              "end": {
                "line": 98,
                "column": 14
              }
            },
            "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "class", "btn btn-info");
            var el4 = dom.createTextNode("Modificar");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [15, 1]);
            var morphs = new Array(8);
            morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element3, [5]), 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element3, [7]), 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element3, [9]), 0, 0);
            morphs[5] = dom.createMorphAt(dom.childAt(element3, [11]), 0, 0);
            morphs[6] = dom.createMorphAt(dom.childAt(element3, [13]), 0, 0);
            morphs[7] = dom.createElementMorph(element4);
            return morphs;
          },
          statements: [["inline", "format-money", [["get", "loan.loanAmount", ["loc", [null, [65, 37], [65, 52]]]]], [], ["loc", [null, [65, 22], [65, 54]]]], ["inline", "format-money", [["get", "loan.missingAmount", ["loc", [null, [66, 37], [66, 55]]]]], [], ["loc", [null, [66, 22], [66, 57]]]], ["block", "if", [["subexpr", "eq", [["get", "loan.type", ["loc", [null, [68, 30], [68, 39]]]], "PERCENTAGE"], [], ["loc", [null, [68, 26], [68, 53]]]]], [], 0, 1, ["loc", [null, [68, 20], [72, 27]]]], ["block", "if", [["subexpr", "eq", [["get", "loan.concept", ["loc", [null, [75, 30], [75, 42]]]], "unit-damage"], [], ["loc", [null, [75, 26], [75, 57]]]]], [], 2, 3, ["loc", [null, [75, 20], [89, 27]]]], ["content", "loan.observations", ["loc", [null, [91, 22], [91, 43]]]], ["content", "loan.number", ["loc", [null, [92, 22], [92, 37]]]], ["inline", "moment-format", [["get", "loan.dateCreated", ["loc", [null, [93, 38], [93, 54]]]]], [], ["loc", [null, [93, 22], [93, 56]]]], ["element", "action", ["modify", ["get", "loan", ["loc", [null, [95, 62], [95, 66]]]]], [], ["loc", [null, [95, 44], [95, 68]]]]],
          locals: ["loan"],
          templates: [child0, child1, child2, child3]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 10
            },
            "end": {
              "line": 101,
              "column": 10
            }
          },
          "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h3");
          var el2 = dom.createTextNode("Préstamos");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1, "class", "table table-striped");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("thead");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          var el4 = dom.createTextNode("Monto del prestamo");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          var el4 = dom.createTextNode("Monto restante");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          var el4 = dom.createTextNode("Tipo de pago");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          var el4 = dom.createTextNode("Concepto");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          var el4 = dom.createTextNode("Observaciones");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          var el4 = dom.createTextNode("Número");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          var el4 = dom.createTextNode("Fecha del prestamo");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 3]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "account.loans", ["loc", [null, [63, 22], [63, 35]]]]], [], 0, null, ["loc", [null, [63, 14], [98, 23]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 102,
              "column": 10
            },
            "end": {
              "line": 106,
              "column": 10
            }
          },
          "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h3");
          var el2 = dom.createTextNode("Descuentos");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1, "class", "table table-striped");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 107,
              "column": 10
            },
            "end": {
              "line": 133,
              "column": 10
            }
          },
          "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          var el2 = dom.createTextNode("Modificar préstamo");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1, "class", "table table-striped");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createTextNode("Monto del préstamo");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createTextNode("Monto faltante por pagar");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createTextNode("Nuevo monto por pagar");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "class", "btn btn-warning");
          var el6 = dom.createTextNode("Guardar nuevo monto");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [3, 1]);
          var element2 = dom.childAt(element1, [7, 3, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 3]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [3, 3]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [5, 3]), 1, 1);
          morphs[3] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [["inline", "format-money", [["get", "selectedLoan.loanAmount", ["loc", [null, [113, 35], [113, 58]]]]], [], ["loc", [null, [113, 20], [113, 60]]]], ["inline", "format-money", [["get", "selectedLoan.missingAmount", ["loc", [null, [117, 35], [117, 61]]]]], [], ["loc", [null, [117, 20], [117, 63]]]], ["inline", "input", [], ["class", "form-control", "value", ["subexpr", "@mut", [["get", "newAmount", ["loc", [null, [122, 53], [122, 62]]]]], [], []], "type", "number"], ["loc", [null, [122, 18], [122, 78]]]], ["element", "action", ["saveNewAmount", ["get", "selectedLoan", ["loc", [null, [128, 46], [128, 58]]]], ["get", "newAmount", ["loc", [null, [128, 59], [128, 68]]]]], [], ["loc", [null, [128, 21], [128, 70]]]]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 134,
              "column": 10
            },
            "end": {
              "line": 167,
              "column": 10
            }
          },
          "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          var el2 = dom.createTextNode("Autorizar préstamo");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode("Monto");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode("Tipo");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode("Concepto");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode("Observaciones");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode("Número o porcentaje");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "btn btn-success");
          var el2 = dom.createTextNode("Guardar");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [13]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 3, 3);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [5]), 3, 3);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [7]), 3, 3);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [9]), 3, 3);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [11]), 3, 3);
          morphs[5] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["inline", "input", [], ["class", "form-control", "value", ["subexpr", "@mut", [["get", "amount", ["loc", [null, [138, 47], [138, 53]]]]], [], []], "type", "number"], ["loc", [null, [138, 12], [138, 69]]]], ["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "types", ["loc", [null, [142, 36], [142, 41]]]]], [], []], "class", "form-control", "prompt", "Selecciona un tipo", "value", ["subexpr", "@mut", [["get", "selected.type", ["loc", [null, [145, 20], [145, 33]]]]], [], []], "optionValuePath", "content.id", "optionLabelPath", "content.text"], ["loc", [null, [142, 12], [147, 46]]]], ["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "concepts", ["loc", [null, [151, 36], [151, 44]]]]], [], []], "class", "form-control", "prompt", "Selecciona un concepto", "value", ["subexpr", "@mut", [["get", "selected.concept", ["loc", [null, [154, 20], [154, 36]]]]], [], []], "optionValuePath", "content.id", "optionLabelPath", "content.text"], ["loc", [null, [151, 12], [156, 46]]]], ["inline", "input", [], ["class", "form-control", "value", ["subexpr", "@mut", [["get", "observations", ["loc", [null, [160, 47], [160, 59]]]]], [], []], "type", "text"], ["loc", [null, [160, 12], [160, 73]]]], ["inline", "input", [], ["class", "form-control", "value", ["subexpr", "@mut", [["get", "number", ["loc", [null, [164, 47], [164, 53]]]]], [], []], "type", "number"], ["loc", [null, [164, 12], [164, 69]]]], ["element", "action", ["authorize", ["get", "account.employee.id", ["loc", [null, [166, 58], [166, 77]]]], ["get", "amount", ["loc", [null, [166, 78], [166, 84]]]], ["get", "selected.type", ["loc", [null, [166, 85], [166, 98]]]], ["get", "number", ["loc", [null, [166, 99], [166, 105]]]], ["get", "observations", ["loc", [null, [166, 106], [166, 118]]]], ["get", "selected.concept", ["loc", [null, [166, 119], [166, 135]]]]], [], ["loc", [null, [166, 37], [166, 137]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 173,
            "column": 0
          }
        },
        "moduleName": "ticket-sales-frontend/templates/admin-loans.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "dashboard-body");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("nav");
        dom.setAttribute(el2, "class", "navbar navbar-inverse navbar-fixed-top");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-fluid");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "navbar-header");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5, "type", "button");
        dom.setAttribute(el5, "class", "navbar-toggle collapsed");
        dom.setAttribute(el5, "data-toggle", "collapse");
        dom.setAttribute(el5, "data-target", "#navbar");
        dom.setAttribute(el5, "aria-expanded", "false");
        dom.setAttribute(el5, "aria-controls", "navbar");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "sr-only");
        var el7 = dom.createTextNode("Toggle navigation");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "icon-bar");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "icon-bar");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "icon-bar");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "class", "navbar-brand");
        var el6 = dom.createTextNode("Tickets Medrano");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "id", "navbar");
        dom.setAttribute(el4, "class", "navbar-collapse collapse");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5, "class", "nav navbar-nav navbar-right");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createElement("a");
        var el8 = dom.createTextNode("Dashboard");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "");
        var el8 = dom.createTextNode("Salida");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-fluid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-sm-3 col-md-2 sidebar");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        var el6 = dom.createTextNode("Préstamo");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-md-12");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var element7 = dom.childAt(element6, [1, 1, 3, 1]);
        var element8 = dom.childAt(element7, [1, 0]);
        var element9 = dom.childAt(element7, [3, 0]);
        var element10 = dom.childAt(element6, [3, 1]);
        var element11 = dom.childAt(element10, [3]);
        var element12 = dom.childAt(element11, [5]);
        var morphs = new Array(9);
        morphs[0] = dom.createElementMorph(element8);
        morphs[1] = dom.createElementMorph(element9);
        morphs[2] = dom.createMorphAt(dom.childAt(element10, [1]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element11, [3, 1]), 1, 1);
        morphs[4] = dom.createMorphAt(element12, 1, 1);
        morphs[5] = dom.createMorphAt(element12, 2, 2);
        morphs[6] = dom.createMorphAt(element12, 3, 3);
        morphs[7] = dom.createMorphAt(element12, 4, 4);
        morphs[8] = dom.createMorphAt(element12, 5, 5);
        return morphs;
      },
      statements: [["element", "action", ["dashboard"], [], ["loc", [null, [15, 17], [15, 39]]]], ["element", "action", ["logout"], [], ["loc", [null, [16, 22], [16, 41]]]], ["content", "admin-menu", ["loc", [null, [25, 8], [25, 22]]]], ["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "model", ["loc", [null, [31, 36], [31, 41]]]]], [], []], "class", "form-control", "prompt", "Selecciona un operador", "value", ["subexpr", "@mut", [["get", "selected.operator", ["loc", [null, [34, 20], [34, 37]]]]], [], []], "optionValuePath", "content.id", "optionLabelPath", "content.fullName"], ["loc", [null, [31, 12], [36, 50]]]], ["block", "if", [["get", "account.employee", ["loc", [null, [40, 16], [40, 32]]]]], [], 0, null, ["loc", [null, [40, 10], [49, 17]]]], ["block", "if", [["get", "account.loans", ["loc", [null, [50, 16], [50, 29]]]]], [], 1, null, ["loc", [null, [50, 10], [101, 17]]]], ["block", "if", [["get", "account.discounts", ["loc", [null, [102, 16], [102, 33]]]]], [], 2, null, ["loc", [null, [102, 10], [106, 17]]]], ["block", "if", [["subexpr", "eq", [["get", "section", ["loc", [null, [107, 20], [107, 27]]]], "modify"], [], ["loc", [null, [107, 16], [107, 37]]]]], [], 3, null, ["loc", [null, [107, 10], [133, 17]]]], ["block", "if", [["subexpr", "eq", [["get", "section", ["loc", [null, [134, 20], [134, 27]]]], "authorize"], [], ["loc", [null, [134, 16], [134, 40]]]]], [], 4, null, ["loc", [null, [134, 10], [167, 17]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});