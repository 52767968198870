define('ticket-sales-frontend/controllers/view-tickets', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    billingData: {},
    billingAddress: {},
    tz: 'America/Mexico_City',
    actions: {
      search: function search(ticketNumber) {
        var _this = this;

        this.set('saleId', ticketNumber);
        jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/saleData/' + ticketNumber
        }).then(function (saleData) {
          _this.set('sale', saleData);
        });
      },
      searchRfc: function searchRfc(rfc) {
        var _this2 = this;

        jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/lookupBill?rfc=' + rfc
        }).then(function (data) {
          _this2.set('billingData', data.billingData);
          _this2.set('billingAddress', data.billingAddress);
        });
      },
      generateBill: function generateBill(billingData, billingAddress) {
        var _this3 = this;

        jQuery('.loading').show();
        jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/generateBill/' + this.get('saleId'),
          type: 'POST',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify({
            billingData: billingData,
            billingAddress: billingAddress
          })
        }).then(function (data) {
          _this3.set('sale', data);
          jQuery('.loading').hide();
        });
      }
    }
  });
});