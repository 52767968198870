define('ticket-sales-frontend/routes/ticket-dashboard-coupon', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    session: _ember['default'].inject.service('session'),
    getTerminalData: function getTerminalData(obj) {
      var _this = this;

      return _emberData['default'].PromiseObject.create({
        promise: new Promise(function (resolve) {
          localforage.getItem('terminalData').then(function (data) {
            if (data && data.id) {
              _this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/terminal/' + data.id).then(function (terminalData) {
                jQuery.ajax({
                  url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/localTrip',
                  data: JSON.stringify({
                    origin: terminalData.stopOffName,
                    adultCount: 1
                  }),
                  contentType: 'application/json; charset=utf-8',
                  method: 'POST'
                }).then(function (quotes) {
                  obj.terminal = terminalData;
                  obj.quotes = quotes;
                  resolve(obj);
                });
              });
            } else {
              resolve({});
            }
          });
        })
      });
    },
    model: function model() {
      return this.getTerminalData({});
    }
  });
});