define('ticket-sales-frontend/controllers/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    register: {},
    init: function init() {
      this._super();
      _ember['default'].run.schedule('afterRender', this, function () {
        this.send('startRevolution');
      });
    },
    actions: {
      startRevolution: function startRevolution() {
        jQuery('.revolution-slider').revolution({
          dottedOverlay: 'none',
          delay: 8000,
          startwidth: 1170,
          startheight: 646,
          onHoverStop: 'on',
          hideThumbs: 10,
          fullWidth: 'on',
          navigationType: 'none',
          shadow: 0,
          spinner: 'spinner4',
          hideTimeBar: 'on'
        });
      },
      searchTrip: function searchTrip(search) {
        search.searchButton.stop();
        delete search.searchButton;
        this.transitionToRoute('searchResults', { queryParams: search });
      }
    }
  });
});