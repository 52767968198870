define('ticket-sales-frontend/routes/index', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsApplicationRouteMixin, _ember) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    session: _ember['default'].inject.service('session'),
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    sessionAuthenticated: function sessionAuthenticated() {
      jQuery('.opacity-overlay').fadeOut();
    },
    beforeModel: function beforeModel(transition) {
      if (!this.get('session.isAuthenticated')) {
        this.set('session.data.profile', null);
      }
      return this._super.apply(this, arguments);
    },
    activate: function activate() {
      var profile = this.get('session.data.profile');
      if (profile) {
        console.log(profile.permissions);
        if (profile.permissions.indexOf('configure-id') >= 0) {
          this.container.lookup('controller:application').transitionToRoute('admin-dashboard');
        } else if (profile.permissions.indexOf('weekly-payment-id') >= 0) {
          this.container.lookup('controller:application').transitionToRoute('admin-weekly-payments');
        } else if (profile.permissions.indexOf('payments-id') >= 0) {
          this.container.lookup('controller:application').transitionToRoute('admin-payments');
        } else {
          this.container.lookup('controller:application').transitionToRoute('ticket-dashboard');
        }
      }
    }
  });
});