define('ticket-sales-frontend/controllers/trip-dashboard-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    selectedStop: null,
    stops: [{ name: 'hola' }],
    actions: {
      goOn: function goOn(selectedStop) {
        location.href = '/tripDashboard/' + selectedStop.id;
      }
    }
  });
});