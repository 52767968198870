define('ticket-sales-frontend/controllers/admin-trip-guide', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    session: _ember['default'].inject.service('session'),
    section: 'listing',
    subsection: 'paradas',
    advances: [],
    selectedBeginning: {
      name: null
    },
    trips: _ember['default'].computed('selectedBeginning.name', function () {
      var beginning = this.get('selectedBeginning.name');
      return _emberData['default'].PromiseArray.create({
        promise: jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/beginnings?stopOff=' + this.get('selectedBeginning.name')).then(function (data) {
          return data;
        })
      });
    }),
    actions: {
      print: function print(tripDetail, advances) {
        var total = 0;
        for (var advance in advances) {
          if (advances.hasOwnProperty(advance)) {
            if (advances[advance].amount) {
              total += parseInt(advances[advance].amount, 10);
            }
          }
        }
        console.log(this.get('session.data.profile'));
        location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/downloadGuide/' + tripDetail.id + '.pdf?amount=' + total + '&timeZone=' + 'America/Mexico_City' + '&userId=' + this.get('session.data.profile.username');
      },
      save: function save(tripDetail, advances, description) {
        var _this = this;

        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/generateGuide', {
          method: 'POST',
          data: JSON.stringify({
            tripId: tripDetail.id,
            advances: advances,
            description: description
          }),
          contentType: 'application/json; charset=utf-8'
        }).then(function () {
          _this.set('selectedBeginning.name', null);
          _this.set('section', 'listing');
          _this.set('subsection', 'paradas');
        });
      },
      selectSubsection: function selectSubsection(subsection) {
        this.set('subsection', subsection);
      },
      addAdvance: function addAdvance() {
        this.get('advances').pushObject({});
      },
      removeAdvance: function removeAdvance(advance) {
        if (!advance.fixed) {
          this.get('advances').removeObject(advance);
        }
      },
      showTrip: function showTrip(trip) {
        var _this2 = this;

        this.set('advances', [{
          description: 'Anticipo',
          amount: trip.advance,
          fixed: true
        }]);
        this.set('description', '');
        jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/trip/' + trip.id).then(function (data) {
          var stops = {
            beginnings: {},
            endings: {}
          };
          var departureDate = new Date(data.departureDate);
          departureDate.setTime(departureDate.getTime());
          data.tripData.tickets.forEach(function (ticket) {
            if (!stops.beginnings[ticket.startingStop.name]) {
              stops.beginnings[ticket.startingStop.name] = [];
            }
            if (!stops.endings[ticket.endingStop.name]) {
              stops.endings[ticket.endingStop.name] = [];
            }
            stops.beginnings[ticket.startingStop.name].push({
              passengerName: ticket.passengerName,
              seatName: ticket.seatName,
              ticketId: ticket.ticketId
            });
            stops.endings[ticket.endingStop.name].push({
              passengerName: ticket.endingStop.name,
              seatName: ticket.seatName,
              ticketId: ticket.ticketId
            });
          });
          data.tripData.stops.forEach(function (stop) {
            stop.beginnings = stops.beginnings[stop.name];
            stop.endings = stops.endings[stop.name];
            stop.departureDate = new Date(departureDate.getTime());
            stop.hasOps = stop.beginnings && stop.beginnings.length > 0 || stop.endings && stop.endings.length > 0;
            departureDate.setTime(departureDate.getTime() + (stop.travelMinutes + stop.waitingMinutes) * 60 * 1000);
          });
          return data;
        }).then(function (data) {
          _this2.set('section', 'detail');
          _this2.set('tripDetail', data);
        });
      }
    }
  });
});