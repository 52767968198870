define('ticket-sales-frontend/controllers/ticket-check-coupon', ['exports', 'ember', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      search: function search(number) {
        var _this = this;

        jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/accountData/' + number,
          contentType: 'application/json; charset=utf-8'
        }).then(function (data) {
          _this.set('data', data);
        });
      }
    }
  });
});