define('ticket-sales-frontend/controllers/ticket-dashboard', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    authorizedRequest: _ember['default'].inject.service('authorizedRequest'),
    booth: {},
    tz: 'America/Mexico_City',
    saleType: 'search',
    currentStep: 'departure',
    currentIds: [],
    quotes: [],
    sale: {},
    discount: {},
    passengerTypes: [{ id: 'ADULT', name: 'Adulto' }, { id: 'OLDER_ADULT', name: 'Adulto Mayor' }, { id: 'CHILD', name: 'Niño' }, { id: 'STUDENT', name: 'Estudiante' }, { id: 'INFANT', name: 'Infante' }],
    canReserve: _ember['default'].computed('sale.departureTrip.maxReserved', 'sale.departureTrip.reservedCount', 'passengers', function () {
      var passengers = this.get('passengers');
      return passengers.length + this.get('sale.departureTrip.reservedCount') <= this.get('sale.departureTrip.maxReserved');
    }),
    passengers: [{ 'delete': false, passengerType: 'ADULT' }],
    subtotalPrice: _ember['default'].computed('totalPrice', function () {
      return this.get('totalPrice') / 1.16;
    }),
    taxes: _ember['default'].computed('subtotalPrice', function () {
      return this.get('subtotalPrice') * 0.16;
    }),
    totalPrice: _ember['default'].computed('passengers.@each.passengerType', function () {
      this.calculatePrice();
      return this.get('sale.totalPrice');
    }),
    sufficientSeats: _ember['default'].observer('enablePayment', 'passengers.@each.passengerType', function () {
      var studentCount = this.get('sale.departureTrip.studentCount');
      var olderAdultCount = this.get('sale.departureTrip.olderAdultCount');

      var maxStudents = this.get('sale.departureTrip.maxStudents');
      var maxOlderAdultCount = this.get('sale.departureTrip.maxOlderAdults');

      var passengers = this.get('passengers');

      var soldStudents = passengers.filter(function (passenger) {
        return passenger.passengerType == 'STUDENT';
      }).length;

      var soldOlderAdults = passengers.filter(function (passenger) {
        return passenger.passengerType == 'OLDER_ADULT';
      }).length;

      var enoughPlace = studentCount + soldStudents <= maxStudents && olderAdultCount + soldOlderAdults <= maxOlderAdultCount;

      return enoughPlace && this.get('enablePayment');
    }),
    getTripData: function getTripData(quote) {
      var tripData = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/tripData';
      return _emberData['default'].PromiseObject.create({
        promise: jQuery.get(tripData, {
          routeId: quote.route.id,
          serviceLevelId: quote.serviceLevelId,
          originId: quote.origin.id,
          destinationId: quote.destination.id,
          tripDate: quote.routeDepartingDate,
          reverse: quote.reverse
        })
      });
    },
    calculatePrice: function calculatePrice() {
      var totalPrice = 0;
      var passengers = this.get('passengers');
      this.get('quotes').forEach(function (quote) {
        var quotePrice = 0;
        passengers.forEach(function (passenger) {
          if (passenger.passengerType === 'ADULT') {
            quotePrice += quote.quote.adultPrice;
            passenger.originalPrice = quote.quote.adultPrice;
            passenger.departurePrice = quote.quote.adultPrice;
            passenger.returnPrice = quote.quote.adultPrice;
          } else if (passenger.passengerType === 'OLDER_ADULT') {
            quotePrice += quote.quote.olderAdultPrice;
            passenger.originalPrice = quote.quote.adultPrice;
            passenger.departurePrice = quote.quote.olderAdultPrice;
            passenger.returnPrice = quote.quote.olderAdultPrice;
          } else if (passenger.passengerType === 'CHILD') {
            quotePrice += quote.quote.childPrice;
            passenger.originalPrice = quote.quote.adultPrice;
            passenger.departurePrice = quote.quote.childPrice;
            passenger.returnPrice = quote.quote.childPrice;
          } else if (passenger.passengerType === 'STUDENT') {
            quotePrice += quote.quote.studentPrice;
            passenger.originalPrice = quote.quote.adultPrice;
            passenger.departurePrice = quote.quote.studentPrice;
            passenger.returnPrice = quote.quote.studentPrice;
          } else {
            passenger.originalPrice = 0;
            passenger.departurePrice = 0;
            passenger.returnPrice = 0;
          }
        });
        _ember['default'].set(quote, 'subtotalPrice', quotePrice / 1.16);
        _ember['default'].set(quote, 'taxes', quotePrice / 1.16 * 0.16);
        _ember['default'].set(quote, 'totalPrice', quotePrice);
        totalPrice += quotePrice;
      });

      this.set('sale.totalPrice', totalPrice);
    },
    actions: {
      startShift: function startShift() {
        var _this = this;

        var username = this.get('session.data.profile.username');
        localforage.getItem('terminalData').then(function (data) {
          _this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/startShift?terminalId=' + data.id + '&employeeId=' + username + '&amount=0').then(function () {
            _this.set('model.boothStatus', true);
            location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/downloadStartShift/' + data.id + '.pdf?timeZone=' + 'America/Mexico_City';
          });
        });
      },
      recordSnapshot: function recordSnapshot() {
        this.set('startSnapshot', true);
      },
      saveRecordSnapshot: function saveRecordSnapshot(booth) {
        var _this2 = this;

        console.log(booth);
        localforage.getItem('terminalData').then(function (data) {
          _this2.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/recordSnapshot?terminalId=' + data.id + '&amount=' + booth.amount).then(function (start) {
            _ember['default'].set(booth, 'startSnapshot', false);
            location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/downloadRecordSnapshot/' + data.id + '.pdf?timeZone=' + 'America/Mexico_City';
          });
        });
      },
      endShift: function endShift() {
        var _this3 = this;

        localforage.getItem('terminalData').then(function (data) {
          _this3.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/closeShift?terminalId=' + data.id).then(function (close) {
            _this3.set('model.boothStatus', false);
            location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/booth/downloadCloseShift/' + data.id + '.pdf?timeZone=' + 'America/Mexico_City';
          });
        });
      },
      addPassenger: function addPassenger() {
        var idx = 0;
        this.get('passengers').addObject({ 'delete': true, passengerType: 'ADULT' });
        this.get('passengers').forEach(function (p) {
          _ember['default'].set(p, 'idx', idx++);
        });
      },
      deletePassenger: function deletePassenger(passenger) {
        this.get('passengers').removeObject(passenger);
      },
      selectQuote: function selectQuote(quote) {
        window.scrollTo(0, 0);
        var trip = this.getTripData(quote);
        var mode = this.get('currentStep');
        quote.mode = mode;
        this.calculatePrice();
        //this.set('searchDepartures', null);
        var passengers = this.get('passengers');
        this.set('sale.departureTrip', trip);
        this.set('sale.departureQuote', quote);
        this.set('currentStep', 'confirm');
        this.set('currentSearchParams', {
          origin: quote.origin.name,
          destination: quote.destination.name,
          olderAdultCount: 0,
          infantCount: 0,
          childrenCount: 0,
          adultCount: 1
        });
        if (this.get('quotes').length > 0) {
          this.get('quotes').popObject();
        }
        if (passengers) {
          passengers.forEach(function (passenger) {
            _ember['default'].set(passenger, 'departureSeat', null);
          });
        }
        this.get('quotes').addObject({
          quote: quote,
          trip: trip
        });
      },
      selectLocal: function selectLocal() {
        this.set('saleType', 'local');
      },
      selectSearch: function selectSearch() {
        this.set('saleType', 'search');
      },
      searchTrip: function searchTrip(params) {
        var _this4 = this;

        var searchButton = params.searchButton;
        params.olderAdultCount = 0;
        params.infantCount = 0;
        params.childrenCount = 0;
        params.adultCount = 1;
        params.timeZone = 'America/Mexico_City';
        delete params.searchButton;
        if (params.departureDate == '2017-04-03T05:00:00.000Z') {
          params.departureDate = '2017-04-03T07:00:00.000Z';
        }
        jQuery.ajax({
          url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/search/trip',
          data: JSON.stringify(params),
          contentType: 'application/json; charset=utf-8',
          method: 'POST'
        }).then(function (data) {
          var startTime = moment(params.departureDate).startOf('day');
          var endTime = moment(params.departureDate).endOf('day');
          _this4.set('searchDepartures', data.departureQuotes);
          _this4.set('searchDepartures', data.departureQuotes.filter(function (current) {
            var currentDate = moment(current.departingDate);
            return startTime.isSameOrBefore(currentDate) && currentDate.isSameOrBefore(endTime);
          }));
          searchButton.stop();
        }, function () {
          searchButton.stop();
        });
      },
      continueWithoutReturn: function continueWithoutReturn() {
        this.calculatePrice();
        this.set('currentStep', 'confirm');
      },
      returnToBus: function returnToBus() {
        this.calculatePrice();
        this.set('currentStep', 'confirm');
      },
      reserve: function reserve(sale, passengers) {
        var _this5 = this;

        var saleRequest = {
          passengers: passengers,
          timeZone: 'America/Mexico_City',
          email: 'dev.tickets.medrano@gmail.com',
          terminalId: this.get('model.terminal.id'),
          departureTrip: {
            id: sale && sale.departureTrip && sale.departureTrip.get('id')
          },
          departureSegment: {
            startingStop: {
              id: sale && sale.departureQuote && sale.departureQuote.origin.id
            },
            endingStop: {
              id: sale && sale.departureQuote && sale.departureQuote.destination.id
            }
          }
        };
        jQuery('.loading').show();
        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/reserve', {
          method: 'POST',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify(saleRequest)
        }).then(function (data) {
          jQuery('.loading').hide();
          _this5.calculatePrice();
          _this5.set('currentStep', 'reserved');
          _this5.set('reservationCode', data.shortId);
          _this5.set('currentIds', []);
          _this5.set('quotes', []);
          _this5.set('sale', {});
          _this5.set('passengers', [{
            'delete': false,
            passengerType: 'ADULT'
          }]);
          _this5.set('searchDepartures', null);
          _this5.get('paymentComponent').reset();
          _this5.get('ticketSearch').reset();
        });
      },
      paymentReceived: function paymentReceived(paymentData) {
        var _this6 = this;

        paymentData.email = 'dev.tickets.medrano@gmail.com';
        paymentData.terminalId = this.get('model.terminal.id');
        jQuery('.loading').show();
        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/payBooth', {
          method: 'POST',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify(paymentData)
        }).then(function (data) {
          jQuery.ajax({
            url: _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/saleData/' + data.shortId
          }).then(function (saleData) {
            location.href = _ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/downloadTickets/' + saleData.id + '.pdf?timeZone=' + 'America/Mexico_City';
            jQuery('.loading').hide();
            _this6.calculatePrice();
            _this6.set('currentStep', 'departure');
            _this6.set('currentIds', []);
            _this6.set('quotes', []);
            _this6.set('sale', {});
            _this6.set('passengers', [{
              'delete': false,
              passengerType: 'ADULT'
            }]);
            _this6.set('searchDepartures', null);
            _this6.get('paymentComponent').reset();
            _this6.get('ticketSearch').reset();
          });
        });
      },
      seatsSelected: function seatsSelected(trip /*, quote*/) {
        this.get('currentIds').addObject(trip.content.id);
        var allIds = this.get('quotes').map(function (quote) {
          return quote.trip.content.id;
        });
        var currentIds = this.get('currentIds');
        var allSelected = allIds.every(function (el) {
          return currentIds.indexOf(el) >= 0;
        });

        this.calculatePrice();

        if (allSelected) {
          this.calculatePrice();
          this.set('enablePayment', true);
          //this.set('currentStep', 'payment');
        }
      },
      returnToQuotes: function returnToQuotes() {
        this.calculatePrice();
        this.set('currentStep', 'departure');
      },
      selectPayment: function selectPayment() {
        var passengers = this.get('passengers');
        var discountedPassengers = passengers.filter(function (passenger) {
          return passenger.passengerType !== 'ADULT';
        }).length;

        this.calculatePrice();
        if (discountedPassengers > 0) {
          // this.set('currentStep', 'discountPassword');
          this.calculatePrice();
          this.set('currentStep', 'payment');
        } else {
          this.set('currentStep', 'payment');
        }
      },
      checkDiscountPassword: function checkDiscountPassword() {
        var _this7 = this;

        jQuery('.loading').show();
        var discount = this.get('discount');
        this.get('authorizedRequest').ajax(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/sale/checkUserDetail', {
          method: 'POST',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify(discount)
        }).then(function (data) {
          jQuery('.loading').hide();

          _this7.calculatePrice();
          if (data.valid) {
            _this7.set('currentStep', 'payment');
          } else {
            _this7.set('discountError', 'El password es incorrecto');
          }
        });
      }
    }
  });
});