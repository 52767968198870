define('ticket-sales-frontend/routes/admin-loans', ['exports', 'ember', 'ember-data', 'ticket-sales-frontend/config/environment'], function (exports, _ember, _emberData, _ticketSalesFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _emberData['default'].PromiseArray.create({
        promise: jQuery.get(_ticketSalesFrontendConfigEnvironment['default'].apiURL + '/loan/operators').then(function (data) {
          data.forEach(function (el) {
            el.fullName = el.name + ' ' + el.lastName;
            if (el.secondLastName) {
              el.fullName += ' ' + el.secondLastName;
            }
            return el;
          });
          data.sort(function (a, b) {
            return a.fullName.localeCompare(b.fullName);
          });
          return data;
        })
      });
    }
  });
});